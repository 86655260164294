@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Cedarville+Cursive:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Dancing+Script:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Sacramento:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Allura:300,400,600,700);
/* line 21, ../bootstrap/scss/_reboot.scss */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* line 27, ../bootstrap/scss/_reboot.scss */
html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent;
}

/* line 45, ../bootstrap/scss/_reboot.scss */
article,
aside,
dialog,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

/* line 57, ../bootstrap/scss/_reboot.scss */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

/* line 73, ../bootstrap/scss/_reboot.scss */
[tabindex="-1"]:focus {
  outline: 0 !important;
}

/* line 83, ../bootstrap/scss/_reboot.scss */
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

/* line 99, ../bootstrap/scss/_reboot.scss */
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.75em;
}

/* line 109, ../bootstrap/scss/_reboot.scss */
p {
  margin-top: 0;
  margin-bottom: 1rem;
}

/* line 121, ../bootstrap/scss/_reboot.scss */
abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
}

/* line 129, ../bootstrap/scss/_reboot.scss */
address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

/* line 135, ../bootstrap/scss/_reboot.scss */
ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

/* line 142, ../bootstrap/scss/_reboot.scss */
ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

/* line 149, ../bootstrap/scss/_reboot.scss */
dt {
  font-weight: 700;
}

/* line 153, ../bootstrap/scss/_reboot.scss */
dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

/* line 158, ../bootstrap/scss/_reboot.scss */
blockquote {
  margin: 0 0 1rem;
}

/* line 162, ../bootstrap/scss/_reboot.scss */
dfn {
  font-style: italic;
}

/* line 167, ../bootstrap/scss/_reboot.scss */
b,
strong {
  font-weight: bolder;
}

/* line 173, ../bootstrap/scss/_reboot.scss */
small {
  font-size: 80%;
}

/* line 182, ../bootstrap/scss/_reboot.scss */
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

/* line 190, ../bootstrap/scss/_reboot.scss */
sub {
  bottom: -0.25em;
}

/* line 191, ../bootstrap/scss/_reboot.scss */
sup {
  top: -0.5em;
}

/* line 198, ../bootstrap/scss/_reboot.scss */
a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

/* line 15, ../bootstrap/scss/mixins/_hover.scss */
a:hover {
  color: #0056b3;
  text-decoration: underline;
}

/* line 216, ../bootstrap/scss/_reboot.scss */
a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}

/* line 19, ../bootstrap/scss/mixins/_hover.scss */
a:not([href]):not([tabindex]):hover,
a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}

/* line 225, ../bootstrap/scss/_reboot.scss */
a:not([href]):not([tabindex]):focus {
  outline: 0;
}

/* line 236, ../bootstrap/scss/_reboot.scss */
pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

/* line 245, ../bootstrap/scss/_reboot.scss */
pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

/* line 262, ../bootstrap/scss/_reboot.scss */
figure {
  margin: 0 0 1rem;
}

/* line 272, ../bootstrap/scss/_reboot.scss */
img {
  vertical-align: middle;
  border-style: none;
}

/* line 277, ../bootstrap/scss/_reboot.scss */
svg:not(:root) {
  overflow: hidden;
}

/* line 286, ../bootstrap/scss/_reboot.scss */
table {
  border-collapse: collapse;
}

/* line 290, ../bootstrap/scss/_reboot.scss */
caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

/* line 298, ../bootstrap/scss/_reboot.scss */
th {
  text-align: inherit;
}

/* line 309, ../bootstrap/scss/_reboot.scss */
label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

/* line 318, ../bootstrap/scss/_reboot.scss */
button {
  border-radius: 0;
}

/* line 326, ../bootstrap/scss/_reboot.scss */
button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

/* line 331, ../bootstrap/scss/_reboot.scss */
input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

/* line 342, ../bootstrap/scss/_reboot.scss */
button,
input {
  overflow: visible;
}

/* line 347, ../bootstrap/scss/_reboot.scss */
button,
select {
  text-transform: none;
}

/* line 355, ../bootstrap/scss/_reboot.scss */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

/* line 363, ../bootstrap/scss/_reboot.scss */
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

/* line 371, ../bootstrap/scss/_reboot.scss */
input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

/* line 378, ../bootstrap/scss/_reboot.scss */
input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}

/* line 390, ../bootstrap/scss/_reboot.scss */
textarea {
  overflow: auto;
  resize: vertical;
}

/* line 396, ../bootstrap/scss/_reboot.scss */
fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

/* line 411, ../bootstrap/scss/_reboot.scss */
legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

/* line 423, ../bootstrap/scss/_reboot.scss */
progress {
  vertical-align: baseline;
}

/* line 428, ../bootstrap/scss/_reboot.scss */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

/* line 433, ../bootstrap/scss/_reboot.scss */
[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

/* line 446, ../bootstrap/scss/_reboot.scss */
[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/* line 456, ../bootstrap/scss/_reboot.scss */
::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

/* line 465, ../bootstrap/scss/_reboot.scss */
output {
  display: inline-block;
}

/* line 469, ../bootstrap/scss/_reboot.scss */
summary {
  display: list-item;
  cursor: pointer;
}

/* line 474, ../bootstrap/scss/_reboot.scss */
template {
  display: none;
}

/* line 480, ../bootstrap/scss/_reboot.scss */
[hidden] {
  display: none !important;
}

/* line 7, ../bootstrap/scss/_type.scss */
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0.75em;
  font-family: inherit;
  font-weight: 700;
  line-height: 1.2;
  color: inherit;
}

/* line 16, ../bootstrap/scss/_type.scss */
h1,
.h1 {
  font-size: 2.5rem;
}

/* line 17, ../bootstrap/scss/_type.scss */
h2,
.h2 {
  font-size: 2rem;
  font-weight: 300 !important;
}

/* line 18, ../bootstrap/scss/_type.scss */
h3,
.h3 {
  font-size: 1.75rem;
}

/* line 19, ../bootstrap/scss/_type.scss */
h4,
.h4 {
  font-size: 1.5rem;
}

/* line 20, ../bootstrap/scss/_type.scss */
h5,
.h5 {
  font-size: 1.25rem;
}

/* line 21, ../bootstrap/scss/_type.scss */
h6,
.h6 {
  font-size: 1rem;
}

/* line 23, ../bootstrap/scss/_type.scss */
.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

/* line 29, ../bootstrap/scss/_type.scss */
.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

/* line 34, ../bootstrap/scss/_type.scss */
.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

/* line 39, ../bootstrap/scss/_type.scss */
.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

/* line 44, ../bootstrap/scss/_type.scss */
.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

/* line 55, ../bootstrap/scss/_type.scss */
hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

/* line 67, ../bootstrap/scss/_type.scss */
small,
.small {
  font-size: 80%;
  font-weight: 400;
}

/* line 73, ../bootstrap/scss/_type.scss */
mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

/* line 84, ../bootstrap/scss/_type.scss */
.list-unstyled {
  padding-left: 0;
  list-style: none;
}

/* line 89, ../bootstrap/scss/_type.scss */
.list-inline {
  padding-left: 0;
  list-style: none;
}

/* line 92, ../bootstrap/scss/_type.scss */
.list-inline-item {
  display: inline-block;
}

/* line 95, ../bootstrap/scss/_type.scss */
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

/* line 106, ../bootstrap/scss/_type.scss */
.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

/* line 112, ../bootstrap/scss/_type.scss */
.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

/* line 117, ../bootstrap/scss/_type.scss */
.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}

/* line 122, ../bootstrap/scss/_type.scss */
.blockquote-footer::before {
  content: "\2014   \A0";
}

/* line 8, ../bootstrap/scss/_images.scss */
.img-fluid {
  max-width: 100%;
  height: auto;
}

/* line 14, ../bootstrap/scss/_images.scss */
.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

/* line 29, ../bootstrap/scss/_images.scss */
.figure {
  display: inline-block;
}

/* line 34, ../bootstrap/scss/_images.scss */
.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

/* line 39, ../bootstrap/scss/_images.scss */
.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

/* line 6, ../bootstrap/scss/_grid.scss */
.container {
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {

  /* line 6, ../bootstrap/scss/_grid.scss */
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {

  /* line 6, ../bootstrap/scss/_grid.scss */
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {

  /* line 6, ../bootstrap/scss/_grid.scss */
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {

  /* line 6, ../bootstrap/scss/_grid.scss */
  .container {
    max-width: 1270px;
  }
}

/* line 18, ../bootstrap/scss/_grid.scss */
.container-fluid {
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  margin-right: auto;
  margin-left: auto;
}

/* line 28, ../bootstrap/scss/_grid.scss */
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -20px;
  margin-left: -20px;
}

/* line 34, ../bootstrap/scss/_grid.scss */
.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

/* line 38, ../bootstrap/scss/_grid.scss */
.no-gutters>.col,
.no-gutters>[class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

/* line 8, ../bootstrap/scss/mixins/_grid-framework.scss */
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 20px;
  padding-left: 20px;
}

/* line 32, ../bootstrap/scss/mixins/_grid-framework.scss */
.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

/* line 37, ../bootstrap/scss/mixins/_grid-framework.scss */
.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}

/* line 44, ../bootstrap/scss/mixins/_grid-framework.scss */
.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
}

/* line 44, ../bootstrap/scss/mixins/_grid-framework.scss */
.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

/* line 44, ../bootstrap/scss/mixins/_grid-framework.scss */
.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

/* line 44, ../bootstrap/scss/mixins/_grid-framework.scss */
.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

/* line 44, ../bootstrap/scss/mixins/_grid-framework.scss */
.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
}

/* line 44, ../bootstrap/scss/mixins/_grid-framework.scss */
.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

/* line 44, ../bootstrap/scss/mixins/_grid-framework.scss */
.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
}

/* line 44, ../bootstrap/scss/mixins/_grid-framework.scss */
.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
}

/* line 44, ../bootstrap/scss/mixins/_grid-framework.scss */
.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

/* line 44, ../bootstrap/scss/mixins/_grid-framework.scss */
.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
}

/* line 44, ../bootstrap/scss/mixins/_grid-framework.scss */
.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%;
}

/* line 44, ../bootstrap/scss/mixins/_grid-framework.scss */
.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

/* line 49, ../bootstrap/scss/mixins/_grid-framework.scss */
.order-first {
  order: -1;
}

/* line 51, ../bootstrap/scss/mixins/_grid-framework.scss */
.order-last {
  order: 13;
}

/* line 54, ../bootstrap/scss/mixins/_grid-framework.scss */
.order-0 {
  order: 0;
}

/* line 54, ../bootstrap/scss/mixins/_grid-framework.scss */
.order-1 {
  order: 1;
}

/* line 54, ../bootstrap/scss/mixins/_grid-framework.scss */
.order-2 {
  order: 2;
}

/* line 54, ../bootstrap/scss/mixins/_grid-framework.scss */
.order-3 {
  order: 3;
}

/* line 54, ../bootstrap/scss/mixins/_grid-framework.scss */
.order-4 {
  order: 4;
}

/* line 54, ../bootstrap/scss/mixins/_grid-framework.scss */
.order-5 {
  order: 5;
}

/* line 54, ../bootstrap/scss/mixins/_grid-framework.scss */
.order-6 {
  order: 6;
}

/* line 54, ../bootstrap/scss/mixins/_grid-framework.scss */
.order-7 {
  order: 7;
}

/* line 54, ../bootstrap/scss/mixins/_grid-framework.scss */
.order-8 {
  order: 8;
}

/* line 54, ../bootstrap/scss/mixins/_grid-framework.scss */
.order-9 {
  order: 9;
}

/* line 54, ../bootstrap/scss/mixins/_grid-framework.scss */
.order-10 {
  order: 10;
}

/* line 54, ../bootstrap/scss/mixins/_grid-framework.scss */
.order-11 {
  order: 11;
}

/* line 54, ../bootstrap/scss/mixins/_grid-framework.scss */
.order-12 {
  order: 12;
}

/* line 60, ../bootstrap/scss/mixins/_grid-framework.scss */
.offset-1 {
  margin-left: 8.33333%;
}

/* line 60, ../bootstrap/scss/mixins/_grid-framework.scss */
.offset-2 {
  margin-left: 16.66667%;
}

/* line 60, ../bootstrap/scss/mixins/_grid-framework.scss */
.offset-3 {
  margin-left: 25%;
}

/* line 60, ../bootstrap/scss/mixins/_grid-framework.scss */
.offset-4 {
  margin-left: 33.33333%;
}

/* line 60, ../bootstrap/scss/mixins/_grid-framework.scss */
.offset-5 {
  margin-left: 41.66667%;
}

/* line 60, ../bootstrap/scss/mixins/_grid-framework.scss */
.offset-6 {
  margin-left: 50%;
}

/* line 60, ../bootstrap/scss/mixins/_grid-framework.scss */
.offset-7 {
  margin-left: 58.33333%;
}

/* line 60, ../bootstrap/scss/mixins/_grid-framework.scss */
.offset-8 {
  margin-left: 66.66667%;
}

/* line 60, ../bootstrap/scss/mixins/_grid-framework.scss */
.offset-9 {
  margin-left: 75%;
}

/* line 60, ../bootstrap/scss/mixins/_grid-framework.scss */
.offset-10 {
  margin-left: 83.33333%;
}

/* line 60, ../bootstrap/scss/mixins/_grid-framework.scss */
.offset-11 {
  margin-left: 91.66667%;
}

@media (min-width: 576px) {

  /* line 32, ../bootstrap/scss/mixins/_grid-framework.scss */
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  /* line 37, ../bootstrap/scss/mixins/_grid-framework.scss */
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  /* line 44, ../bootstrap/scss/mixins/_grid-framework.scss */
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  /* line 44, ../bootstrap/scss/mixins/_grid-framework.scss */
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  /* line 44, ../bootstrap/scss/mixins/_grid-framework.scss */
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  /* line 44, ../bootstrap/scss/mixins/_grid-framework.scss */
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  /* line 44, ../bootstrap/scss/mixins/_grid-framework.scss */
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  /* line 44, ../bootstrap/scss/mixins/_grid-framework.scss */
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  /* line 44, ../bootstrap/scss/mixins/_grid-framework.scss */
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  /* line 44, ../bootstrap/scss/mixins/_grid-framework.scss */
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  /* line 44, ../bootstrap/scss/mixins/_grid-framework.scss */
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  /* line 44, ../bootstrap/scss/mixins/_grid-framework.scss */
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  /* line 44, ../bootstrap/scss/mixins/_grid-framework.scss */
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  /* line 44, ../bootstrap/scss/mixins/_grid-framework.scss */
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  /* line 49, ../bootstrap/scss/mixins/_grid-framework.scss */
  .order-sm-first {
    order: -1;
  }

  /* line 51, ../bootstrap/scss/mixins/_grid-framework.scss */
  .order-sm-last {
    order: 13;
  }

  /* line 54, ../bootstrap/scss/mixins/_grid-framework.scss */
  .order-sm-0 {
    order: 0;
  }

  /* line 54, ../bootstrap/scss/mixins/_grid-framework.scss */
  .order-sm-1 {
    order: 1;
  }

  /* line 54, ../bootstrap/scss/mixins/_grid-framework.scss */
  .order-sm-2 {
    order: 2;
  }

  /* line 54, ../bootstrap/scss/mixins/_grid-framework.scss */
  .order-sm-3 {
    order: 3;
  }

  /* line 54, ../bootstrap/scss/mixins/_grid-framework.scss */
  .order-sm-4 {
    order: 4;
  }

  /* line 54, ../bootstrap/scss/mixins/_grid-framework.scss */
  .order-sm-5 {
    order: 5;
  }

  /* line 54, ../bootstrap/scss/mixins/_grid-framework.scss */
  .order-sm-6 {
    order: 6;
  }

  /* line 54, ../bootstrap/scss/mixins/_grid-framework.scss */
  .order-sm-7 {
    order: 7;
  }

  /* line 54, ../bootstrap/scss/mixins/_grid-framework.scss */
  .order-sm-8 {
    order: 8;
  }

  /* line 54, ../bootstrap/scss/mixins/_grid-framework.scss */
  .order-sm-9 {
    order: 9;
  }

  /* line 54, ../bootstrap/scss/mixins/_grid-framework.scss */
  .order-sm-10 {
    order: 10;
  }

  /* line 54, ../bootstrap/scss/mixins/_grid-framework.scss */
  .order-sm-11 {
    order: 11;
  }

  /* line 54, ../bootstrap/scss/mixins/_grid-framework.scss */
  .order-sm-12 {
    order: 12;
  }

  /* line 60, ../bootstrap/scss/mixins/_grid-framework.scss */
  .offset-sm-0 {
    margin-left: 0;
  }

  /* line 60, ../bootstrap/scss/mixins/_grid-framework.scss */
  .offset-sm-1 {
    margin-left: 8.33333%;
  }

  /* line 60, ../bootstrap/scss/mixins/_grid-framework.scss */
  .offset-sm-2 {
    margin-left: 16.66667%;
  }

  /* line 60, ../bootstrap/scss/mixins/_grid-framework.scss */
  .offset-sm-3 {
    margin-left: 25%;
  }

  /* line 60, ../bootstrap/scss/mixins/_grid-framework.scss */
  .offset-sm-4 {
    margin-left: 33.33333%;
  }

  /* line 60, ../bootstrap/scss/mixins/_grid-framework.scss */
  .offset-sm-5 {
    margin-left: 41.66667%;
  }

  /* line 60, ../bootstrap/scss/mixins/_grid-framework.scss */
  .offset-sm-6 {
    margin-left: 50%;
  }

  /* line 60, ../bootstrap/scss/mixins/_grid-framework.scss */
  .offset-sm-7 {
    margin-left: 58.33333%;
  }

  /* line 60, ../bootstrap/scss/mixins/_grid-framework.scss */
  .offset-sm-8 {
    margin-left: 66.66667%;
  }

  /* line 60, ../bootstrap/scss/mixins/_grid-framework.scss */
  .offset-sm-9 {
    margin-left: 75%;
  }

  /* line 60, ../bootstrap/scss/mixins/_grid-framework.scss */
  .offset-sm-10 {
    margin-left: 83.33333%;
  }

  /* line 60, ../bootstrap/scss/mixins/_grid-framework.scss */
  .offset-sm-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 768px) {

  /* line 32, ../bootstrap/scss/mixins/_grid-framework.scss */
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  /* line 37, ../bootstrap/scss/mixins/_grid-framework.scss */
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  /* line 44, ../bootstrap/scss/mixins/_grid-framework.scss */
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  /* line 44, ../bootstrap/scss/mixins/_grid-framework.scss */
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  /* line 44, ../bootstrap/scss/mixins/_grid-framework.scss */
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  /* line 44, ../bootstrap/scss/mixins/_grid-framework.scss */
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  /* line 44, ../bootstrap/scss/mixins/_grid-framework.scss */
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  /* line 44, ../bootstrap/scss/mixins/_grid-framework.scss */
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  /* line 44, ../bootstrap/scss/mixins/_grid-framework.scss */
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  /* line 44, ../bootstrap/scss/mixins/_grid-framework.scss */
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  /* line 44, ../bootstrap/scss/mixins/_grid-framework.scss */
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  /* line 44, ../bootstrap/scss/mixins/_grid-framework.scss */
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  /* line 44, ../bootstrap/scss/mixins/_grid-framework.scss */
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  /* line 44, ../bootstrap/scss/mixins/_grid-framework.scss */
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  /* line 49, ../bootstrap/scss/mixins/_grid-framework.scss */
  .order-md-first {
    order: -1;
  }

  /* line 51, ../bootstrap/scss/mixins/_grid-framework.scss */
  .order-md-last {
    order: 13;
  }

  /* line 54, ../bootstrap/scss/mixins/_grid-framework.scss */
  .order-md-0 {
    order: 0;
  }

  /* line 54, ../bootstrap/scss/mixins/_grid-framework.scss */
  .order-md-1 {
    order: 1;
  }

  /* line 54, ../bootstrap/scss/mixins/_grid-framework.scss */
  .order-md-2 {
    order: 2;
  }

  /* line 54, ../bootstrap/scss/mixins/_grid-framework.scss */
  .order-md-3 {
    order: 3;
  }

  /* line 54, ../bootstrap/scss/mixins/_grid-framework.scss */
  .order-md-4 {
    order: 4;
  }

  /* line 54, ../bootstrap/scss/mixins/_grid-framework.scss */
  .order-md-5 {
    order: 5;
  }

  /* line 54, ../bootstrap/scss/mixins/_grid-framework.scss */
  .order-md-6 {
    order: 6;
  }

  /* line 54, ../bootstrap/scss/mixins/_grid-framework.scss */
  .order-md-7 {
    order: 7;
  }

  /* line 54, ../bootstrap/scss/mixins/_grid-framework.scss */
  .order-md-8 {
    order: 8;
  }

  /* line 54, ../bootstrap/scss/mixins/_grid-framework.scss */
  .order-md-9 {
    order: 9;
  }

  /* line 54, ../bootstrap/scss/mixins/_grid-framework.scss */
  .order-md-10 {
    order: 10;
  }

  /* line 54, ../bootstrap/scss/mixins/_grid-framework.scss */
  .order-md-11 {
    order: 11;
  }

  /* line 54, ../bootstrap/scss/mixins/_grid-framework.scss */
  .order-md-12 {
    order: 12;
  }

  /* line 60, ../bootstrap/scss/mixins/_grid-framework.scss */
  .offset-md-0 {
    margin-left: 0;
  }

  /* line 60, ../bootstrap/scss/mixins/_grid-framework.scss */
  .offset-md-1 {
    margin-left: 8.33333%;
  }

  /* line 60, ../bootstrap/scss/mixins/_grid-framework.scss */
  .offset-md-2 {
    margin-left: 16.66667%;
  }

  /* line 60, ../bootstrap/scss/mixins/_grid-framework.scss */
  .offset-md-3 {
    margin-left: 25%;
  }

  /* line 60, ../bootstrap/scss/mixins/_grid-framework.scss */
  .offset-md-4 {
    margin-left: 33.33333%;
  }

  /* line 60, ../bootstrap/scss/mixins/_grid-framework.scss */
  .offset-md-5 {
    margin-left: 41.66667%;
  }

  /* line 60, ../bootstrap/scss/mixins/_grid-framework.scss */
  .offset-md-6 {
    margin-left: 50%;
  }

  /* line 60, ../bootstrap/scss/mixins/_grid-framework.scss */
  .offset-md-7 {
    margin-left: 58.33333%;
  }

  /* line 60, ../bootstrap/scss/mixins/_grid-framework.scss */
  .offset-md-8 {
    margin-left: 66.66667%;
  }

  /* line 60, ../bootstrap/scss/mixins/_grid-framework.scss */
  .offset-md-9 {
    margin-left: 75%;
  }

  /* line 60, ../bootstrap/scss/mixins/_grid-framework.scss */
  .offset-md-10 {
    margin-left: 83.33333%;
  }

  /* line 60, ../bootstrap/scss/mixins/_grid-framework.scss */
  .offset-md-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 992px) {

  /* line 32, ../bootstrap/scss/mixins/_grid-framework.scss */
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  /* line 37, ../bootstrap/scss/mixins/_grid-framework.scss */
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  /* line 44, ../bootstrap/scss/mixins/_grid-framework.scss */
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  /* line 44, ../bootstrap/scss/mixins/_grid-framework.scss */
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  /* line 44, ../bootstrap/scss/mixins/_grid-framework.scss */
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  /* line 44, ../bootstrap/scss/mixins/_grid-framework.scss */
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  /* line 44, ../bootstrap/scss/mixins/_grid-framework.scss */
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  /* line 44, ../bootstrap/scss/mixins/_grid-framework.scss */
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  /* line 44, ../bootstrap/scss/mixins/_grid-framework.scss */
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  /* line 44, ../bootstrap/scss/mixins/_grid-framework.scss */
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  /* line 44, ../bootstrap/scss/mixins/_grid-framework.scss */
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  /* line 44, ../bootstrap/scss/mixins/_grid-framework.scss */
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  /* line 44, ../bootstrap/scss/mixins/_grid-framework.scss */
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  /* line 44, ../bootstrap/scss/mixins/_grid-framework.scss */
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  /* line 49, ../bootstrap/scss/mixins/_grid-framework.scss */
  .order-lg-first {
    order: -1;
  }

  /* line 51, ../bootstrap/scss/mixins/_grid-framework.scss */
  .order-lg-last {
    order: 13;
  }

  /* line 54, ../bootstrap/scss/mixins/_grid-framework.scss */
  .order-lg-0 {
    order: 0;
  }

  /* line 54, ../bootstrap/scss/mixins/_grid-framework.scss */
  .order-lg-1 {
    order: 1;
  }

  /* line 54, ../bootstrap/scss/mixins/_grid-framework.scss */
  .order-lg-2 {
    order: 2;
  }

  /* line 54, ../bootstrap/scss/mixins/_grid-framework.scss */
  .order-lg-3 {
    order: 3;
  }

  /* line 54, ../bootstrap/scss/mixins/_grid-framework.scss */
  .order-lg-4 {
    order: 4;
  }

  /* line 54, ../bootstrap/scss/mixins/_grid-framework.scss */
  .order-lg-5 {
    order: 5;
  }

  /* line 54, ../bootstrap/scss/mixins/_grid-framework.scss */
  .order-lg-6 {
    order: 6;
  }

  /* line 54, ../bootstrap/scss/mixins/_grid-framework.scss */
  .order-lg-7 {
    order: 7;
  }

  /* line 54, ../bootstrap/scss/mixins/_grid-framework.scss */
  .order-lg-8 {
    order: 8;
  }

  /* line 54, ../bootstrap/scss/mixins/_grid-framework.scss */
  .order-lg-9 {
    order: 9;
  }

  /* line 54, ../bootstrap/scss/mixins/_grid-framework.scss */
  .order-lg-10 {
    order: 10;
  }

  /* line 54, ../bootstrap/scss/mixins/_grid-framework.scss */
  .order-lg-11 {
    order: 11;
  }

  /* line 54, ../bootstrap/scss/mixins/_grid-framework.scss */
  .order-lg-12 {
    order: 12;
  }

  /* line 60, ../bootstrap/scss/mixins/_grid-framework.scss */
  .offset-lg-0 {
    margin-left: 0;
  }

  /* line 60, ../bootstrap/scss/mixins/_grid-framework.scss */
  .offset-lg-1 {
    margin-left: 8.33333%;
  }

  /* line 60, ../bootstrap/scss/mixins/_grid-framework.scss */
  .offset-lg-2 {
    margin-left: 16.66667%;
  }

  /* line 60, ../bootstrap/scss/mixins/_grid-framework.scss */
  .offset-lg-3 {
    margin-left: 25%;
  }

  /* line 60, ../bootstrap/scss/mixins/_grid-framework.scss */
  .offset-lg-4 {
    margin-left: 33.33333%;
  }

  /* line 60, ../bootstrap/scss/mixins/_grid-framework.scss */
  .offset-lg-5 {
    margin-left: 41.66667%;
  }

  /* line 60, ../bootstrap/scss/mixins/_grid-framework.scss */
  .offset-lg-6 {
    margin-left: 50%;
  }

  /* line 60, ../bootstrap/scss/mixins/_grid-framework.scss */
  .offset-lg-7 {
    margin-left: 58.33333%;
  }

  /* line 60, ../bootstrap/scss/mixins/_grid-framework.scss */
  .offset-lg-8 {
    margin-left: 66.66667%;
  }

  /* line 60, ../bootstrap/scss/mixins/_grid-framework.scss */
  .offset-lg-9 {
    margin-left: 75%;
  }

  /* line 60, ../bootstrap/scss/mixins/_grid-framework.scss */
  .offset-lg-10 {
    margin-left: 83.33333%;
  }

  /* line 60, ../bootstrap/scss/mixins/_grid-framework.scss */
  .offset-lg-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 1200px) {

  /* line 32, ../bootstrap/scss/mixins/_grid-framework.scss */
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  /* line 37, ../bootstrap/scss/mixins/_grid-framework.scss */
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  /* line 44, ../bootstrap/scss/mixins/_grid-framework.scss */
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  /* line 44, ../bootstrap/scss/mixins/_grid-framework.scss */
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  /* line 44, ../bootstrap/scss/mixins/_grid-framework.scss */
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  /* line 44, ../bootstrap/scss/mixins/_grid-framework.scss */
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  /* line 44, ../bootstrap/scss/mixins/_grid-framework.scss */
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  /* line 44, ../bootstrap/scss/mixins/_grid-framework.scss */
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  /* line 44, ../bootstrap/scss/mixins/_grid-framework.scss */
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  /* line 44, ../bootstrap/scss/mixins/_grid-framework.scss */
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  /* line 44, ../bootstrap/scss/mixins/_grid-framework.scss */
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  /* line 44, ../bootstrap/scss/mixins/_grid-framework.scss */
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  /* line 44, ../bootstrap/scss/mixins/_grid-framework.scss */
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  /* line 44, ../bootstrap/scss/mixins/_grid-framework.scss */
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  /* line 49, ../bootstrap/scss/mixins/_grid-framework.scss */
  .order-xl-first {
    order: -1;
  }

  /* line 51, ../bootstrap/scss/mixins/_grid-framework.scss */
  .order-xl-last {
    order: 13;
  }

  /* line 54, ../bootstrap/scss/mixins/_grid-framework.scss */
  .order-xl-0 {
    order: 0;
  }

  /* line 54, ../bootstrap/scss/mixins/_grid-framework.scss */
  .order-xl-1 {
    order: 1;
  }

  /* line 54, ../bootstrap/scss/mixins/_grid-framework.scss */
  .order-xl-2 {
    order: 2;
  }

  /* line 54, ../bootstrap/scss/mixins/_grid-framework.scss */
  .order-xl-3 {
    order: 3;
  }

  /* line 54, ../bootstrap/scss/mixins/_grid-framework.scss */
  .order-xl-4 {
    order: 4;
  }

  /* line 54, ../bootstrap/scss/mixins/_grid-framework.scss */
  .order-xl-5 {
    order: 5;
  }

  /* line 54, ../bootstrap/scss/mixins/_grid-framework.scss */
  .order-xl-6 {
    order: 6;
  }

  /* line 54, ../bootstrap/scss/mixins/_grid-framework.scss */
  .order-xl-7 {
    order: 7;
  }

  /* line 54, ../bootstrap/scss/mixins/_grid-framework.scss */
  .order-xl-8 {
    order: 8;
  }

  /* line 54, ../bootstrap/scss/mixins/_grid-framework.scss */
  .order-xl-9 {
    order: 9;
  }

  /* line 54, ../bootstrap/scss/mixins/_grid-framework.scss */
  .order-xl-10 {
    order: 10;
  }

  /* line 54, ../bootstrap/scss/mixins/_grid-framework.scss */
  .order-xl-11 {
    order: 11;
  }

  /* line 54, ../bootstrap/scss/mixins/_grid-framework.scss */
  .order-xl-12 {
    order: 12;
  }

  /* line 60, ../bootstrap/scss/mixins/_grid-framework.scss */
  .offset-xl-0 {
    margin-left: 0;
  }

  /* line 60, ../bootstrap/scss/mixins/_grid-framework.scss */
  .offset-xl-1 {
    margin-left: 8.33333%;
  }

  /* line 60, ../bootstrap/scss/mixins/_grid-framework.scss */
  .offset-xl-2 {
    margin-left: 16.66667%;
  }

  /* line 60, ../bootstrap/scss/mixins/_grid-framework.scss */
  .offset-xl-3 {
    margin-left: 25%;
  }

  /* line 60, ../bootstrap/scss/mixins/_grid-framework.scss */
  .offset-xl-4 {
    margin-left: 33.33333%;
  }

  /* line 60, ../bootstrap/scss/mixins/_grid-framework.scss */
  .offset-xl-5 {
    margin-left: 41.66667%;
  }

  /* line 60, ../bootstrap/scss/mixins/_grid-framework.scss */
  .offset-xl-6 {
    margin-left: 50%;
  }

  /* line 60, ../bootstrap/scss/mixins/_grid-framework.scss */
  .offset-xl-7 {
    margin-left: 58.33333%;
  }

  /* line 60, ../bootstrap/scss/mixins/_grid-framework.scss */
  .offset-xl-8 {
    margin-left: 66.66667%;
  }

  /* line 60, ../bootstrap/scss/mixins/_grid-framework.scss */
  .offset-xl-9 {
    margin-left: 75%;
  }

  /* line 60, ../bootstrap/scss/mixins/_grid-framework.scss */
  .offset-xl-10 {
    margin-left: 83.33333%;
  }

  /* line 60, ../bootstrap/scss/mixins/_grid-framework.scss */
  .offset-xl-11 {
    margin-left: 91.66667%;
  }
}

/* line 7, ../bootstrap/scss/_buttons.scss */
.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
          user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

/* line 19, ../bootstrap/scss/mixins/_hover.scss */
.btn:hover,
.btn:focus {
  text-decoration: none;
}

/* line 23, ../bootstrap/scss/_buttons.scss */
.btn:focus,
.btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

/* line 30, ../bootstrap/scss/_buttons.scss */
.btn.disabled,
.btn:disabled {
  opacity: 0.65;
}

/* line 37, ../bootstrap/scss/_buttons.scss */
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

/* line 41, ../bootstrap/scss/_buttons.scss */
.btn:not(:disabled):not(.disabled):active,
.btn:not(:disabled):not(.disabled).active {
  background-image: none;
}

/* line 53, ../bootstrap/scss/_buttons.scss */
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

/* line 64, ../bootstrap/scss/_buttons.scss */
.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

/* line 15, ../bootstrap/scss/mixins/_hover.scss */
.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}

/* line 18, ../bootstrap/scss/mixins/_buttons.scss */
.btn-primary:focus,
.btn-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

/* line 29, ../bootstrap/scss/mixins/_buttons.scss */
.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

/* line 36, ../bootstrap/scss/mixins/_buttons.scss */
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show>.btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
}

/* line 46, ../bootstrap/scss/mixins/_buttons.scss */
.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show>.btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

/* line 64, ../bootstrap/scss/_buttons.scss */
.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

/* line 15, ../bootstrap/scss/mixins/_hover.scss */
.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}

/* line 18, ../bootstrap/scss/mixins/_buttons.scss */
.btn-secondary:focus,
.btn-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

/* line 29, ../bootstrap/scss/mixins/_buttons.scss */
.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

/* line 36, ../bootstrap/scss/mixins/_buttons.scss */
.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show>.btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}

/* line 46, ../bootstrap/scss/mixins/_buttons.scss */
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show>.btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

/* line 64, ../bootstrap/scss/_buttons.scss */
.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

/* line 15, ../bootstrap/scss/mixins/_hover.scss */
.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}

/* line 18, ../bootstrap/scss/mixins/_buttons.scss */
.btn-success:focus,
.btn-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

/* line 29, ../bootstrap/scss/mixins/_buttons.scss */
.btn-success.disabled,
.btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

/* line 36, ../bootstrap/scss/mixins/_buttons.scss */
.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled).active,
.show>.btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}

/* line 46, ../bootstrap/scss/mixins/_buttons.scss */
.btn-success:not(:disabled):not(.disabled):active:focus,
.btn-success:not(:disabled):not(.disabled).active:focus,
.show>.btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

/* line 64, ../bootstrap/scss/_buttons.scss */
.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

/* line 15, ../bootstrap/scss/mixins/_hover.scss */
.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}

/* line 18, ../bootstrap/scss/mixins/_buttons.scss */
.btn-info:focus,
.btn-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

/* line 29, ../bootstrap/scss/mixins/_buttons.scss */
.btn-info.disabled,
.btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

/* line 36, ../bootstrap/scss/mixins/_buttons.scss */
.btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled).active,
.show>.btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}

/* line 46, ../bootstrap/scss/mixins/_buttons.scss */
.btn-info:not(:disabled):not(.disabled):active:focus,
.btn-info:not(:disabled):not(.disabled).active:focus,
.show>.btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

/* line 64, ../bootstrap/scss/_buttons.scss */
.btn-warning {
  color: #212529;
  background-color: #feb325;
  border-color: #feb325;
}

/* line 15, ../bootstrap/scss/mixins/_hover.scss */
.btn-warning:hover {
  color: #212529;
  background-color: #fca501;
  border-color: #ef9d01;
}

/* line 18, ../bootstrap/scss/mixins/_buttons.scss */
.btn-warning:focus,
.btn-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(254, 179, 37, 0.5);
}

/* line 29, ../bootstrap/scss/mixins/_buttons.scss */
.btn-warning.disabled,
.btn-warning:disabled {
  color: #212529;
  background-color: #feb325;
  border-color: #feb325;
}

/* line 36, ../bootstrap/scss/mixins/_buttons.scss */
.btn-warning:not(:disabled):not(.disabled):active,
.btn-warning:not(:disabled):not(.disabled).active,
.show>.btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #ef9d01;
  border-color: #e29401;
}

/* line 46, ../bootstrap/scss/mixins/_buttons.scss */
.btn-warning:not(:disabled):not(.disabled):active:focus,
.btn-warning:not(:disabled):not(.disabled).active:focus,
.show>.btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(254, 179, 37, 0.5);
}

/* line 64, ../bootstrap/scss/_buttons.scss */
.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

/* line 15, ../bootstrap/scss/mixins/_hover.scss */
.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}

/* line 18, ../bootstrap/scss/mixins/_buttons.scss */
.btn-danger:focus,
.btn-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

/* line 29, ../bootstrap/scss/mixins/_buttons.scss */
.btn-danger.disabled,
.btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

/* line 36, ../bootstrap/scss/mixins/_buttons.scss */
.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:not(:disabled):not(.disabled).active,
.show>.btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}

/* line 46, ../bootstrap/scss/mixins/_buttons.scss */
.btn-danger:not(:disabled):not(.disabled):active:focus,
.btn-danger:not(:disabled):not(.disabled).active:focus,
.show>.btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

/* line 64, ../bootstrap/scss/_buttons.scss */
.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

/* line 15, ../bootstrap/scss/mixins/_hover.scss */
.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}

/* line 18, ../bootstrap/scss/mixins/_buttons.scss */
.btn-light:focus,
.btn-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

/* line 29, ../bootstrap/scss/mixins/_buttons.scss */
.btn-light.disabled,
.btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

/* line 36, ../bootstrap/scss/mixins/_buttons.scss */
.btn-light:not(:disabled):not(.disabled):active,
.btn-light:not(:disabled):not(.disabled).active,
.show>.btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}

/* line 46, ../bootstrap/scss/mixins/_buttons.scss */
.btn-light:not(:disabled):not(.disabled):active:focus,
.btn-light:not(:disabled):not(.disabled).active:focus,
.show>.btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

/* line 64, ../bootstrap/scss/_buttons.scss */
.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

/* line 15, ../bootstrap/scss/mixins/_hover.scss */
.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}

/* line 18, ../bootstrap/scss/mixins/_buttons.scss */
.btn-dark:focus,
.btn-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

/* line 29, ../bootstrap/scss/mixins/_buttons.scss */
.btn-dark.disabled,
.btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

/* line 36, ../bootstrap/scss/mixins/_buttons.scss */
.btn-dark:not(:disabled):not(.disabled):active,
.btn-dark:not(:disabled):not(.disabled).active,
.show>.btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}

/* line 46, ../bootstrap/scss/mixins/_buttons.scss */
.btn-dark:not(:disabled):not(.disabled):active:focus,
.btn-dark:not(:disabled):not(.disabled).active:focus,
.show>.btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

/* line 70, ../bootstrap/scss/_buttons.scss */
.btn-outline-primary {
  color: #007bff;
  background-color: transparent;
  background-image: none;
  border-color: #007bff;
}

/* line 63, ../bootstrap/scss/mixins/_buttons.scss */
.btn-outline-primary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

/* line 69, ../bootstrap/scss/mixins/_buttons.scss */
.btn-outline-primary:focus,
.btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

/* line 74, ../bootstrap/scss/mixins/_buttons.scss */
.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #007bff;
  background-color: transparent;
}

/* line 80, ../bootstrap/scss/mixins/_buttons.scss */
.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show>.btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

/* line 87, ../bootstrap/scss/mixins/_buttons.scss */
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

/* line 70, ../bootstrap/scss/_buttons.scss */
.btn-outline-secondary {
  color: #6c757d;
  background-color: transparent;
  background-image: none;
  border-color: #6c757d;
}

/* line 63, ../bootstrap/scss/mixins/_buttons.scss */
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

/* line 69, ../bootstrap/scss/mixins/_buttons.scss */
.btn-outline-secondary:focus,
.btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

/* line 74, ../bootstrap/scss/mixins/_buttons.scss */
.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}

/* line 80, ../bootstrap/scss/mixins/_buttons.scss */
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show>.btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

/* line 87, ../bootstrap/scss/mixins/_buttons.scss */
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

/* line 70, ../bootstrap/scss/_buttons.scss */
.btn-outline-success {
  color: #28a745;
  background-color: transparent;
  background-image: none;
  border-color: #28a745;
}

/* line 63, ../bootstrap/scss/mixins/_buttons.scss */
.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

/* line 69, ../bootstrap/scss/mixins/_buttons.scss */
.btn-outline-success:focus,
.btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

/* line 74, ../bootstrap/scss/mixins/_buttons.scss */
.btn-outline-success.disabled,
.btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}

/* line 80, ../bootstrap/scss/mixins/_buttons.scss */
.btn-outline-success:not(:disabled):not(.disabled):active,
.btn-outline-success:not(:disabled):not(.disabled).active,
.show>.btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

/* line 87, ../bootstrap/scss/mixins/_buttons.scss */
.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

/* line 70, ../bootstrap/scss/_buttons.scss */
.btn-outline-info {
  color: #17a2b8;
  background-color: transparent;
  background-image: none;
  border-color: #17a2b8;
}

/* line 63, ../bootstrap/scss/mixins/_buttons.scss */
.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

/* line 69, ../bootstrap/scss/mixins/_buttons.scss */
.btn-outline-info:focus,
.btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

/* line 74, ../bootstrap/scss/mixins/_buttons.scss */
.btn-outline-info.disabled,
.btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}

/* line 80, ../bootstrap/scss/mixins/_buttons.scss */
.btn-outline-info:not(:disabled):not(.disabled):active,
.btn-outline-info:not(:disabled):not(.disabled).active,
.show>.btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

/* line 87, ../bootstrap/scss/mixins/_buttons.scss */
.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

/* line 70, ../bootstrap/scss/_buttons.scss */
.btn-outline-warning {
  color: #feb325;
  background-color: transparent;
  background-image: none;
  border-color: #feb325;
}

/* line 63, ../bootstrap/scss/mixins/_buttons.scss */
.btn-outline-warning:hover {
  color: #212529;
  background-color: #feb325;
  border-color: #feb325;
}

/* line 69, ../bootstrap/scss/mixins/_buttons.scss */
.btn-outline-warning:focus,
.btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(254, 179, 37, 0.5);
}

/* line 74, ../bootstrap/scss/mixins/_buttons.scss */
.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  color: #feb325;
  background-color: transparent;
}

/* line 80, ../bootstrap/scss/mixins/_buttons.scss */
.btn-outline-warning:not(:disabled):not(.disabled):active,
.btn-outline-warning:not(:disabled):not(.disabled).active,
.show>.btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #feb325;
  border-color: #feb325;
}

/* line 87, ../bootstrap/scss/mixins/_buttons.scss */
.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(254, 179, 37, 0.5);
}

/* line 70, ../bootstrap/scss/_buttons.scss */
.btn-outline-danger {
  color: #dc3545;
  background-color: transparent;
  background-image: none;
  border-color: #dc3545;
}

/* line 63, ../bootstrap/scss/mixins/_buttons.scss */
.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

/* line 69, ../bootstrap/scss/mixins/_buttons.scss */
.btn-outline-danger:focus,
.btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

/* line 74, ../bootstrap/scss/mixins/_buttons.scss */
.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}

/* line 80, ../bootstrap/scss/mixins/_buttons.scss */
.btn-outline-danger:not(:disabled):not(.disabled):active,
.btn-outline-danger:not(:disabled):not(.disabled).active,
.show>.btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

/* line 87, ../bootstrap/scss/mixins/_buttons.scss */
.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

/* line 70, ../bootstrap/scss/_buttons.scss */
.btn-outline-light {
  color: #f8f9fa;
  background-color: transparent;
  background-image: none;
  border-color: #f8f9fa;
}

/* line 63, ../bootstrap/scss/mixins/_buttons.scss */
.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

/* line 69, ../bootstrap/scss/mixins/_buttons.scss */
.btn-outline-light:focus,
.btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

/* line 74, ../bootstrap/scss/mixins/_buttons.scss */
.btn-outline-light.disabled,
.btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}

/* line 80, ../bootstrap/scss/mixins/_buttons.scss */
.btn-outline-light:not(:disabled):not(.disabled):active,
.btn-outline-light:not(:disabled):not(.disabled).active,
.show>.btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

/* line 87, ../bootstrap/scss/mixins/_buttons.scss */
.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

/* line 70, ../bootstrap/scss/_buttons.scss */
.btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40;
}

/* line 63, ../bootstrap/scss/mixins/_buttons.scss */
.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

/* line 69, ../bootstrap/scss/mixins/_buttons.scss */
.btn-outline-dark:focus,
.btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

/* line 74, ../bootstrap/scss/mixins/_buttons.scss */
.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}

/* line 80, ../bootstrap/scss/mixins/_buttons.scss */
.btn-outline-dark:not(:disabled):not(.disabled):active,
.btn-outline-dark:not(:disabled):not(.disabled).active,
.show>.btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

/* line 87, ../bootstrap/scss/mixins/_buttons.scss */
.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

/* line 81, ../bootstrap/scss/_buttons.scss */
.btn-link {
  font-weight: 400;
  color: #007bff;
  background-color: transparent;
}

/* line 15, ../bootstrap/scss/mixins/_hover.scss */
.btn-link:hover {
  color: #0056b3;
  text-decoration: underline;
  background-color: transparent;
  border-color: transparent;
}

/* line 93, ../bootstrap/scss/_buttons.scss */
.btn-link:focus,
.btn-link.focus {
  text-decoration: underline;
  border-color: transparent;
  box-shadow: none;
}

/* line 100, ../bootstrap/scss/_buttons.scss */
.btn-link:disabled,
.btn-link.disabled {
  color: #6c757d;
}

/* line 113, ../bootstrap/scss/_buttons.scss */
.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

/* line 117, ../bootstrap/scss/_buttons.scss */
.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

/* line 126, ../bootstrap/scss/_buttons.scss */
.btn-block {
  display: block;
  width: 100%;
}

/* line 131, ../bootstrap/scss/_buttons.scss */
.btn-block+.btn-block {
  margin-top: 0.5rem;
}

/* line 140, ../bootstrap/scss/_buttons.scss */
input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}

/* line 7, ../bootstrap/scss/_forms.scss */
.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control-checkout-acknowledge {
  display: block;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

/* line 31, ../bootstrap/scss/_forms.scss */
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

/* line 14, ../bootstrap/scss/mixins/_forms.scss */
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.form-control-checkout-acknowledge:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}


/* line 40, ../bootstrap/scss/_forms.scss */
.form-control::placeholder {
  color: rgba(0, 0, 0, 0.5);
  opacity: 0.5;
}

.form-group select.form-control {
  /* color: rgba(0, 0, 0, 0.25); */
}

.form-group select.form-control,
.form-group select.form-control {
  /* color: rgba(0, 0, 0, 0.5); */
}

/* line 51, ../bootstrap/scss/_forms.scss */
.form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

/* line 60, ../bootstrap/scss/_forms.scss */
select.form-control:not([size]):not([multiple]) {
  height: calc(2.25rem + 2px);
}

/* line 64, ../bootstrap/scss/_forms.scss */
select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

/* line 76, ../bootstrap/scss/_forms.scss */
.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

/* line 89, ../bootstrap/scss/_forms.scss */
.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

/* line 97, ../bootstrap/scss/_forms.scss */
.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

/* line 104, ../bootstrap/scss/_forms.scss */
.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

/* line 117, ../bootstrap/scss/_forms.scss */
.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

/* line 128, ../bootstrap/scss/_forms.scss */
.form-control-plaintext.form-control-sm,
.form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

/* line 144, ../bootstrap/scss/_forms.scss */
.form-control-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

/* line 152, ../bootstrap/scss/_forms.scss */
select.form-control-sm:not([size]):not([multiple]) {
  height: calc(1.8125rem + 2px);
}

/* line 157, ../bootstrap/scss/_forms.scss */
.form-control-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

/* line 165, ../bootstrap/scss/_forms.scss */
select.form-control-lg:not([size]):not([multiple]) {
  height: calc(2.875rem + 2px);
}

/* line 176, ../bootstrap/scss/_forms.scss */
.form-group {
  margin-bottom: 1rem;
}

/* line 180, ../bootstrap/scss/_forms.scss */
.form-text {
  display: block;
  margin-top: 0.25rem;
}

/* line 190, ../bootstrap/scss/_forms.scss */
.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

/* line 196, ../bootstrap/scss/_forms.scss */
.form-row>.col,
.form-row>[class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

/* line 208, ../bootstrap/scss/_forms.scss */
.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

/* line 214, ../bootstrap/scss/_forms.scss */
.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}

/* line 219, ../bootstrap/scss/_forms.scss */
.form-check-input:disabled~.form-check-label {
  color: #6c757d;
}

/* line 224, ../bootstrap/scss/_forms.scss */
.form-check-label {
  margin-bottom: 0;
}

/* line 228, ../bootstrap/scss/_forms.scss */
.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}

/* line 235, ../bootstrap/scss/_forms.scss */
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

/* line 30, ../bootstrap/scss/mixins/_forms.scss */
.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745;
}

/* line 38, ../bootstrap/scss/mixins/_forms.scss */
.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.8);
  border-radius: 0.2rem;
}

/* line 55, ../bootstrap/scss/mixins/_forms.scss */
.was-validated .form-control:valid,
.form-control.is-valid,
.was-validated .custom-select:valid,
.custom-select.is-valid {
  border-color: #28a745;
}

/* line 59, ../bootstrap/scss/mixins/_forms.scss */
.was-validated .form-control:valid:focus,
.form-control.is-valid:focus,
.was-validated .custom-select:valid:focus,
.custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

/* line 64, ../bootstrap/scss/mixins/_forms.scss */
.was-validated .form-control:valid~.valid-feedback,
.was-validated .form-control:valid~.valid-tooltip,
.form-control.is-valid~.valid-feedback,
.form-control.is-valid~.valid-tooltip,
.was-validated .custom-select:valid~.valid-feedback,
.was-validated .custom-select:valid~.valid-tooltip,
.custom-select.is-valid~.valid-feedback,
.custom-select.is-valid~.valid-tooltip {
  display: block;
}

/* line 74, ../bootstrap/scss/mixins/_forms.scss */
.was-validated .form-check-input:valid~.form-check-label,
.form-check-input.is-valid~.form-check-label {
  color: #28a745;
}

/* line 78, ../bootstrap/scss/mixins/_forms.scss */
.was-validated .form-check-input:valid~.valid-feedback,
.was-validated .form-check-input:valid~.valid-tooltip,
.form-check-input.is-valid~.valid-feedback,
.form-check-input.is-valid~.valid-tooltip {
  display: block;
}

/* line 88, ../bootstrap/scss/mixins/_forms.scss */
.was-validated .custom-control-input:valid~.custom-control-label,
.custom-control-input.is-valid~.custom-control-label {
  color: #28a745;
}

/* line 91, ../bootstrap/scss/mixins/_forms.scss */
.was-validated .custom-control-input:valid~.custom-control-label::before,
.custom-control-input.is-valid~.custom-control-label::before {
  background-color: #71dd8a;
}

/* line 96, ../bootstrap/scss/mixins/_forms.scss */
.was-validated .custom-control-input:valid~.valid-feedback,
.was-validated .custom-control-input:valid~.valid-tooltip,
.custom-control-input.is-valid~.valid-feedback,
.custom-control-input.is-valid~.valid-tooltip {
  display: block;
}

/* line 102, ../bootstrap/scss/mixins/_forms.scss */
.was-validated .custom-control-input:valid:checked~.custom-control-label::before,
.custom-control-input.is-valid:checked~.custom-control-label::before {
  background-color: #34ce57;
}

/* line 108, ../bootstrap/scss/mixins/_forms.scss */
.was-validated .custom-control-input:valid:focus~.custom-control-label::before,
.custom-control-input.is-valid:focus~.custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

/* line 119, ../bootstrap/scss/mixins/_forms.scss */
.was-validated .custom-file-input:valid~.custom-file-label,
.custom-file-input.is-valid~.custom-file-label {
  border-color: #28a745;
}

/* line 122, ../bootstrap/scss/mixins/_forms.scss */
.was-validated .custom-file-input:valid~.custom-file-label::before,
.custom-file-input.is-valid~.custom-file-label::before {
  border-color: inherit;
}

/* line 125, ../bootstrap/scss/mixins/_forms.scss */
.was-validated .custom-file-input:valid~.valid-feedback,
.was-validated .custom-file-input:valid~.valid-tooltip,
.custom-file-input.is-valid~.valid-feedback,
.custom-file-input.is-valid~.valid-tooltip {
  display: block;
}

/* line 131, ../bootstrap/scss/mixins/_forms.scss */
.was-validated .custom-file-input:valid:focus~.custom-file-label,
.custom-file-input.is-valid:focus~.custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

/* line 30, ../bootstrap/scss/mixins/_forms.scss */
.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

/* line 38, ../bootstrap/scss/mixins/_forms.scss */
.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.8);
  border-radius: 0.2rem;
}

/* line 55, ../bootstrap/scss/mixins/_forms.scss */
.was-validated .form-control:invalid,
.form-control.is-invalid,
.was-validated .custom-select:invalid,
.custom-select.is-invalid {
  border-color: #dc3545;
}

/* line 59, ../bootstrap/scss/mixins/_forms.scss */
.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus,
.was-validated .custom-select:invalid:focus,
.custom-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

/* line 64, ../bootstrap/scss/mixins/_forms.scss */
.was-validated .form-control:invalid~.invalid-feedback,
.was-validated .form-control:invalid~.invalid-tooltip,
.form-control.is-invalid~.invalid-feedback,
.form-control.is-invalid~.invalid-tooltip,
.was-validated .custom-select:invalid~.invalid-feedback,
.was-validated .custom-select:invalid~.invalid-tooltip,
.custom-select.is-invalid~.invalid-feedback,
.custom-select.is-invalid~.invalid-tooltip {
  display: block;
}

/* line 74, ../bootstrap/scss/mixins/_forms.scss */
.was-validated .form-check-input:invalid~.form-check-label,
.form-check-input.is-invalid~.form-check-label {
  color: #dc3545;
}

/* line 78, ../bootstrap/scss/mixins/_forms.scss */
.was-validated .form-check-input:invalid~.invalid-feedback,
.was-validated .form-check-input:invalid~.invalid-tooltip,
.form-check-input.is-invalid~.invalid-feedback,
.form-check-input.is-invalid~.invalid-tooltip {
  display: block;
}

/* line 88, ../bootstrap/scss/mixins/_forms.scss */
.was-validated .custom-control-input:invalid~.custom-control-label,
.custom-control-input.is-invalid~.custom-control-label {
  color: #dc3545;
}

/* line 91, ../bootstrap/scss/mixins/_forms.scss */
.was-validated .custom-control-input:invalid~.custom-control-label::before,
.custom-control-input.is-invalid~.custom-control-label::before {
  background-color: #efa2a9;
}

/* line 96, ../bootstrap/scss/mixins/_forms.scss */
.was-validated .custom-control-input:invalid~.invalid-feedback,
.was-validated .custom-control-input:invalid~.invalid-tooltip,
.custom-control-input.is-invalid~.invalid-feedback,
.custom-control-input.is-invalid~.invalid-tooltip {
  display: block;
}

/* line 102, ../bootstrap/scss/mixins/_forms.scss */
.was-validated .custom-control-input:invalid:checked~.custom-control-label::before,
.custom-control-input.is-invalid:checked~.custom-control-label::before {
  background-color: #e4606d;
}

/* line 108, ../bootstrap/scss/mixins/_forms.scss */
.was-validated .custom-control-input:invalid:focus~.custom-control-label::before,
.custom-control-input.is-invalid:focus~.custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

/* line 119, ../bootstrap/scss/mixins/_forms.scss */
.was-validated .custom-file-input:invalid~.custom-file-label,
.custom-file-input.is-invalid~.custom-file-label {
  border-color: #dc3545;
}

/* line 122, ../bootstrap/scss/mixins/_forms.scss */
.was-validated .custom-file-input:invalid~.custom-file-label::before,
.custom-file-input.is-invalid~.custom-file-label::before {
  border-color: inherit;
}

/* line 125, ../bootstrap/scss/mixins/_forms.scss */
.was-validated .custom-file-input:invalid~.invalid-feedback,
.was-validated .custom-file-input:invalid~.invalid-tooltip,
.custom-file-input.is-invalid~.invalid-feedback,
.custom-file-input.is-invalid~.invalid-tooltip {
  display: block;
}

/* line 131, ../bootstrap/scss/mixins/_forms.scss */
.was-validated .custom-file-input:invalid:focus~.custom-file-label,
.custom-file-input.is-invalid:focus~.custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

/* line 263, ../bootstrap/scss/_forms.scss */
.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

/* line 271, ../bootstrap/scss/_forms.scss */
.form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {

  /* line 277, ../bootstrap/scss/_forms.scss */
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }

  /* line 285, ../bootstrap/scss/_forms.scss */
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }

  /* line 294, ../bootstrap/scss/_forms.scss */
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }

  /* line 301, ../bootstrap/scss/_forms.scss */
  .form-inline .form-control-plaintext {
    display: inline-block;
  }

  /* line 305, ../bootstrap/scss/_forms.scss */
  .form-inline .input-group {
    width: auto;
  }

  /* line 311, ../bootstrap/scss/_forms.scss */
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }

  /* line 318, ../bootstrap/scss/_forms.scss */
  .form-inline .form-check-input {
    position: relative;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }

  /* line 325, ../bootstrap/scss/_forms.scss */
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }

  /* line 329, ../bootstrap/scss/_forms.scss */
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

/* line 3, ../bootstrap/scss/utilities/_align.scss */
.align-baseline {
  vertical-align: baseline !important;
}

/* line 4, ../bootstrap/scss/utilities/_align.scss */
.align-top {
  vertical-align: top !important;
}

/* line 5, ../bootstrap/scss/utilities/_align.scss */
.align-middle {
  vertical-align: middle !important;
}

/* line 6, ../bootstrap/scss/utilities/_align.scss */
.align-bottom {
  vertical-align: bottom !important;
}

/* line 7, ../bootstrap/scss/utilities/_align.scss */
.align-text-bottom {
  vertical-align: text-bottom !important;
}

/* line 8, ../bootstrap/scss/utilities/_align.scss */
.align-text-top {
  vertical-align: text-top !important;
}

/* line 6, ../bootstrap/scss/mixins/_background-variant.scss */
.bg-primary {
  background-color: #007bff !important;
}

/* line 19, ../bootstrap/scss/mixins/_hover.scss */
a.bg-primary:hover,
a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important;
}

/* line 6, ../bootstrap/scss/mixins/_background-variant.scss */
.bg-secondary {
  background-color: #6c757d !important;
}

/* line 19, ../bootstrap/scss/mixins/_hover.scss */
a.bg-secondary:hover,
a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important;
}

/* line 6, ../bootstrap/scss/mixins/_background-variant.scss */
.bg-success {
  background-color: #28a745 !important;
}

/* line 19, ../bootstrap/scss/mixins/_hover.scss */
a.bg-success:hover,
a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important;
}

/* line 6, ../bootstrap/scss/mixins/_background-variant.scss */
.bg-info {
  background-color: #17a2b8 !important;
}

/* line 19, ../bootstrap/scss/mixins/_hover.scss */
a.bg-info:hover,
a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

/* line 6, ../bootstrap/scss/mixins/_background-variant.scss */
.bg-warning {
  background-color: #feb325 !important;
}

/* line 19, ../bootstrap/scss/mixins/_hover.scss */
a.bg-warning:hover,
a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #ef9d01 !important;
}

/* line 6, ../bootstrap/scss/mixins/_background-variant.scss */
.bg-danger {
  background-color: #dc3545 !important;
}

/* line 19, ../bootstrap/scss/mixins/_hover.scss */
a.bg-danger:hover,
a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important;
}

/* line 6, ../bootstrap/scss/mixins/_background-variant.scss */
.bg-light {
  background-color: #f8f9fa !important;
}

/* line 19, ../bootstrap/scss/mixins/_hover.scss */
a.bg-light:hover,
a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

/* line 6, ../bootstrap/scss/mixins/_background-variant.scss */
.bg-dark {
  background-color: #343a40 !important;
}

/* line 19, ../bootstrap/scss/mixins/_hover.scss */
a.bg-dark:hover,
a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

/* line 13, ../bootstrap/scss/utilities/_background.scss */
.bg-white {
  background-color: #fff !important;
}

/* line 17, ../bootstrap/scss/utilities/_background.scss */
.bg-transparent {
  background-color: transparent !important;
}

/* line 7, ../bootstrap/scss/utilities/_borders.scss */
.border {
  border: 1px solid #dee2e6 !important;
}

/* line 8, ../bootstrap/scss/utilities/_borders.scss */
.border-top {
  border-top: 1px solid #dee2e6 !important;
}

/* line 9, ../bootstrap/scss/utilities/_borders.scss */
.border-right {
  border-right: 1px solid #dee2e6 !important;
}

/* line 10, ../bootstrap/scss/utilities/_borders.scss */
.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

/* line 11, ../bootstrap/scss/utilities/_borders.scss */
.border-left {
  border-left: 1px solid #dee2e6 !important;
}

/* line 13, ../bootstrap/scss/utilities/_borders.scss */
.border-0 {
  border: 0 !important;
}

/* line 14, ../bootstrap/scss/utilities/_borders.scss */
.border-top-0 {
  border-top: 0 !important;
}

/* line 15, ../bootstrap/scss/utilities/_borders.scss */
.border-right-0 {
  border-right: 0 !important;
}

/* line 16, ../bootstrap/scss/utilities/_borders.scss */
.border-bottom-0 {
  border-bottom: 0 !important;
}

/* line 17, ../bootstrap/scss/utilities/_borders.scss */
.border-left-0 {
  border-left: 0 !important;
}

/* line 20, ../bootstrap/scss/utilities/_borders.scss */
.border-primary {
  border-color: #007bff !important;
}

/* line 20, ../bootstrap/scss/utilities/_borders.scss */
.border-secondary {
  border-color: #6c757d !important;
}

/* line 20, ../bootstrap/scss/utilities/_borders.scss */
.border-success {
  border-color: #28a745 !important;
}

/* line 20, ../bootstrap/scss/utilities/_borders.scss */
.border-info {
  border-color: #17a2b8 !important;
}

/* line 20, ../bootstrap/scss/utilities/_borders.scss */
.border-warning {
  border-color: #feb325 !important;
}

/* line 20, ../bootstrap/scss/utilities/_borders.scss */
.border-danger {
  border-color: #dc3545 !important;
}

/* line 20, ../bootstrap/scss/utilities/_borders.scss */
.border-light {
  border-color: #f8f9fa !important;
}

/* line 20, ../bootstrap/scss/utilities/_borders.scss */
.border-dark {
  border-color: #343a40 !important;
}

/* line 25, ../bootstrap/scss/utilities/_borders.scss */
.border-white {
  border-color: #fff !important;
}

/* line 33, ../bootstrap/scss/utilities/_borders.scss */
.rounded {
  border-radius: 0.25rem !important;
}

/* line 36, ../bootstrap/scss/utilities/_borders.scss */
.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

/* line 40, ../bootstrap/scss/utilities/_borders.scss */
.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

/* line 44, ../bootstrap/scss/utilities/_borders.scss */
.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

/* line 48, ../bootstrap/scss/utilities/_borders.scss */
.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

/* line 53, ../bootstrap/scss/utilities/_borders.scss */
.rounded-circle {
  border-radius: 50% !important;
}

/* line 57, ../bootstrap/scss/utilities/_borders.scss */
.rounded-0 {
  border-radius: 0 !important;
}

/* line 2, ../bootstrap/scss/mixins/_clearfix.scss */
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

/* line 11, ../bootstrap/scss/utilities/_display.scss */
.d-none {
  display: none !important;
}

/* line 12, ../bootstrap/scss/utilities/_display.scss */
.d-inline {
  display: inline !important;
}

/* line 13, ../bootstrap/scss/utilities/_display.scss */
.d-inline-block {
  display: inline-block !important;
}

/* line 14, ../bootstrap/scss/utilities/_display.scss */
.d-block {
  display: block !important;
}

/* line 15, ../bootstrap/scss/utilities/_display.scss */
.d-table {
  display: table !important;
}

/* line 16, ../bootstrap/scss/utilities/_display.scss */
.d-table-row {
  display: table-row !important;
}

/* line 17, ../bootstrap/scss/utilities/_display.scss */
.d-table-cell {
  display: table-cell !important;
}

/* line 18, ../bootstrap/scss/utilities/_display.scss */
.d-flex {
  display: flex !important;
}

/* line 19, ../bootstrap/scss/utilities/_display.scss */
.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {

  /* line 11, ../bootstrap/scss/utilities/_display.scss */
  .d-sm-none {
    display: none !important;
  }

  /* line 12, ../bootstrap/scss/utilities/_display.scss */
  .d-sm-inline {
    display: inline !important;
  }

  /* line 13, ../bootstrap/scss/utilities/_display.scss */
  .d-sm-inline-block {
    display: inline-block !important;
  }

  /* line 14, ../bootstrap/scss/utilities/_display.scss */
  .d-sm-block {
    display: block !important;
  }

  /* line 15, ../bootstrap/scss/utilities/_display.scss */
  .d-sm-table {
    display: table !important;
  }

  /* line 16, ../bootstrap/scss/utilities/_display.scss */
  .d-sm-table-row {
    display: table-row !important;
  }

  /* line 17, ../bootstrap/scss/utilities/_display.scss */
  .d-sm-table-cell {
    display: table-cell !important;
  }

  /* line 18, ../bootstrap/scss/utilities/_display.scss */
  .d-sm-flex {
    display: flex !important;
  }

  /* line 19, ../bootstrap/scss/utilities/_display.scss */
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {

  /* line 11, ../bootstrap/scss/utilities/_display.scss */
  .d-md-none {
    display: none !important;
  }

  /* line 12, ../bootstrap/scss/utilities/_display.scss */
  .d-md-inline {
    display: inline !important;
  }

  /* line 13, ../bootstrap/scss/utilities/_display.scss */
  .d-md-inline-block {
    display: inline-block !important;
  }

  /* line 14, ../bootstrap/scss/utilities/_display.scss */
  .d-md-block {
    display: block !important;
  }

  /* line 15, ../bootstrap/scss/utilities/_display.scss */
  .d-md-table {
    display: table !important;
  }

  /* line 16, ../bootstrap/scss/utilities/_display.scss */
  .d-md-table-row {
    display: table-row !important;
  }

  /* line 17, ../bootstrap/scss/utilities/_display.scss */
  .d-md-table-cell {
    display: table-cell !important;
  }

  /* line 18, ../bootstrap/scss/utilities/_display.scss */
  .d-md-flex {
    display: flex !important;
  }

  /* line 19, ../bootstrap/scss/utilities/_display.scss */
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {

  /* line 11, ../bootstrap/scss/utilities/_display.scss */
  .d-lg-none {
    display: none !important;
  }

  /* line 12, ../bootstrap/scss/utilities/_display.scss */
  .d-lg-inline {
    display: inline !important;
  }

  /* line 13, ../bootstrap/scss/utilities/_display.scss */
  .d-lg-inline-block {
    display: inline-block !important;
  }

  /* line 14, ../bootstrap/scss/utilities/_display.scss */
  .d-lg-block {
    display: block !important;
  }

  /* line 15, ../bootstrap/scss/utilities/_display.scss */
  .d-lg-table {
    display: table !important;
  }

  /* line 16, ../bootstrap/scss/utilities/_display.scss */
  .d-lg-table-row {
    display: table-row !important;
  }

  /* line 17, ../bootstrap/scss/utilities/_display.scss */
  .d-lg-table-cell {
    display: table-cell !important;
  }

  /* line 18, ../bootstrap/scss/utilities/_display.scss */
  .d-lg-flex {
    display: flex !important;
  }

  /* line 19, ../bootstrap/scss/utilities/_display.scss */
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {

  /* line 11, ../bootstrap/scss/utilities/_display.scss */
  .d-xl-none {
    display: none !important;
  }

  /* line 12, ../bootstrap/scss/utilities/_display.scss */
  .d-xl-inline {
    display: inline !important;
  }

  /* line 13, ../bootstrap/scss/utilities/_display.scss */
  .d-xl-inline-block {
    display: inline-block !important;
  }

  /* line 14, ../bootstrap/scss/utilities/_display.scss */
  .d-xl-block {
    display: block !important;
  }

  /* line 15, ../bootstrap/scss/utilities/_display.scss */
  .d-xl-table {
    display: table !important;
  }

  /* line 16, ../bootstrap/scss/utilities/_display.scss */
  .d-xl-table-row {
    display: table-row !important;
  }

  /* line 17, ../bootstrap/scss/utilities/_display.scss */
  .d-xl-table-cell {
    display: table-cell !important;
  }

  /* line 18, ../bootstrap/scss/utilities/_display.scss */
  .d-xl-flex {
    display: flex !important;
  }

  /* line 19, ../bootstrap/scss/utilities/_display.scss */
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {

  /* line 29, ../bootstrap/scss/utilities/_display.scss */
  .d-print-none {
    display: none !important;
  }

  /* line 30, ../bootstrap/scss/utilities/_display.scss */
  .d-print-inline {
    display: inline !important;
  }

  /* line 31, ../bootstrap/scss/utilities/_display.scss */
  .d-print-inline-block {
    display: inline-block !important;
  }

  /* line 32, ../bootstrap/scss/utilities/_display.scss */
  .d-print-block {
    display: block !important;
  }

  /* line 33, ../bootstrap/scss/utilities/_display.scss */
  .d-print-table {
    display: table !important;
  }

  /* line 34, ../bootstrap/scss/utilities/_display.scss */
  .d-print-table-row {
    display: table-row !important;
  }

  /* line 35, ../bootstrap/scss/utilities/_display.scss */
  .d-print-table-cell {
    display: table-cell !important;
  }

  /* line 36, ../bootstrap/scss/utilities/_display.scss */
  .d-print-flex {
    display: flex !important;
  }

  /* line 37, ../bootstrap/scss/utilities/_display.scss */
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

/* line 3, ../bootstrap/scss/utilities/_embed.scss */
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

/* line 10, ../bootstrap/scss/utilities/_embed.scss */
.embed-responsive::before {
  display: block;
  content: "";
}

/* line 15, ../bootstrap/scss/utilities/_embed.scss */
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

/* line 31, ../bootstrap/scss/utilities/_embed.scss */
.embed-responsive-21by9::before {
  padding-top: 42.85714%;
}

/* line 37, ../bootstrap/scss/utilities/_embed.scss */
.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

/* line 43, ../bootstrap/scss/utilities/_embed.scss */
.embed-responsive-4by3::before {
  padding-top: 75%;
}

/* line 49, ../bootstrap/scss/utilities/_embed.scss */
.embed-responsive-1by1::before {
  padding-top: 100%;
}

/* line 11, ../bootstrap/scss/utilities/_flex.scss */
.flex-row {
  flex-direction: row !important;
}

/* line 12, ../bootstrap/scss/utilities/_flex.scss */
.flex-column {
  flex-direction: column !important;
}

/* line 13, ../bootstrap/scss/utilities/_flex.scss */
.flex-row-reverse {
  flex-direction: row-reverse !important;
}

/* line 14, ../bootstrap/scss/utilities/_flex.scss */
.flex-column-reverse {
  flex-direction: column-reverse !important;
}

/* line 16, ../bootstrap/scss/utilities/_flex.scss */
.flex-wrap {
  flex-wrap: wrap !important;
}

/* line 17, ../bootstrap/scss/utilities/_flex.scss */
.flex-nowrap {
  flex-wrap: nowrap !important;
}

/* line 18, ../bootstrap/scss/utilities/_flex.scss */
.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

/* line 20, ../bootstrap/scss/utilities/_flex.scss */
.justify-content-start {
  justify-content: flex-start !important;
}

/* line 21, ../bootstrap/scss/utilities/_flex.scss */
.justify-content-end {
  justify-content: flex-end !important;
}

/* line 22, ../bootstrap/scss/utilities/_flex.scss */
.justify-content-center {
  justify-content: center !important;
}

/* line 23, ../bootstrap/scss/utilities/_flex.scss */
.justify-content-between {
  justify-content: space-between !important;
}

/* line 24, ../bootstrap/scss/utilities/_flex.scss */
.justify-content-around {
  justify-content: space-around !important;
}

/* line 26, ../bootstrap/scss/utilities/_flex.scss */
.align-items-start {
  align-items: flex-start !important;
}

/* line 27, ../bootstrap/scss/utilities/_flex.scss */
.align-items-end {
  align-items: flex-end !important;
}

/* line 28, ../bootstrap/scss/utilities/_flex.scss */
.align-items-center {
  align-items: center !important;
}

/* line 29, ../bootstrap/scss/utilities/_flex.scss */
.align-items-baseline {
  align-items: baseline !important;
}

/* line 30, ../bootstrap/scss/utilities/_flex.scss */
.align-items-stretch {
  align-items: stretch !important;
}

/* line 32, ../bootstrap/scss/utilities/_flex.scss */
.align-content-start {
  align-content: flex-start !important;
}

/* line 33, ../bootstrap/scss/utilities/_flex.scss */
.align-content-end {
  align-content: flex-end !important;
}

/* line 34, ../bootstrap/scss/utilities/_flex.scss */
.align-content-center {
  align-content: center !important;
}

/* line 35, ../bootstrap/scss/utilities/_flex.scss */
.align-content-between {
  align-content: space-between !important;
}

/* line 36, ../bootstrap/scss/utilities/_flex.scss */
.align-content-around {
  align-content: space-around !important;
}

/* line 37, ../bootstrap/scss/utilities/_flex.scss */
.align-content-stretch {
  align-content: stretch !important;
}

/* line 39, ../bootstrap/scss/utilities/_flex.scss */
.align-self-auto {
  align-self: auto !important;
}

/* line 40, ../bootstrap/scss/utilities/_flex.scss */
.align-self-start {
  align-self: flex-start !important;
}

/* line 41, ../bootstrap/scss/utilities/_flex.scss */
.align-self-end {
  align-self: flex-end !important;
}

/* line 42, ../bootstrap/scss/utilities/_flex.scss */
.align-self-center {
  align-self: center !important;
}

/* line 43, ../bootstrap/scss/utilities/_flex.scss */
.align-self-baseline {
  align-self: baseline !important;
}

/* line 44, ../bootstrap/scss/utilities/_flex.scss */
.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {

  /* line 11, ../bootstrap/scss/utilities/_flex.scss */
  .flex-sm-row {
    flex-direction: row !important;
  }

  /* line 12, ../bootstrap/scss/utilities/_flex.scss */
  .flex-sm-column {
    flex-direction: column !important;
  }

  /* line 13, ../bootstrap/scss/utilities/_flex.scss */
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  /* line 14, ../bootstrap/scss/utilities/_flex.scss */
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  /* line 16, ../bootstrap/scss/utilities/_flex.scss */
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  /* line 17, ../bootstrap/scss/utilities/_flex.scss */
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  /* line 18, ../bootstrap/scss/utilities/_flex.scss */
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  /* line 20, ../bootstrap/scss/utilities/_flex.scss */
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  /* line 21, ../bootstrap/scss/utilities/_flex.scss */
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  /* line 22, ../bootstrap/scss/utilities/_flex.scss */
  .justify-content-sm-center {
    justify-content: center !important;
  }

  /* line 23, ../bootstrap/scss/utilities/_flex.scss */
  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  /* line 24, ../bootstrap/scss/utilities/_flex.scss */
  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  /* line 26, ../bootstrap/scss/utilities/_flex.scss */
  .align-items-sm-start {
    align-items: flex-start !important;
  }

  /* line 27, ../bootstrap/scss/utilities/_flex.scss */
  .align-items-sm-end {
    align-items: flex-end !important;
  }

  /* line 28, ../bootstrap/scss/utilities/_flex.scss */
  .align-items-sm-center {
    align-items: center !important;
  }

  /* line 29, ../bootstrap/scss/utilities/_flex.scss */
  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  /* line 30, ../bootstrap/scss/utilities/_flex.scss */
  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  /* line 32, ../bootstrap/scss/utilities/_flex.scss */
  .align-content-sm-start {
    align-content: flex-start !important;
  }

  /* line 33, ../bootstrap/scss/utilities/_flex.scss */
  .align-content-sm-end {
    align-content: flex-end !important;
  }

  /* line 34, ../bootstrap/scss/utilities/_flex.scss */
  .align-content-sm-center {
    align-content: center !important;
  }

  /* line 35, ../bootstrap/scss/utilities/_flex.scss */
  .align-content-sm-between {
    align-content: space-between !important;
  }

  /* line 36, ../bootstrap/scss/utilities/_flex.scss */
  .align-content-sm-around {
    align-content: space-around !important;
  }

  /* line 37, ../bootstrap/scss/utilities/_flex.scss */
  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  /* line 39, ../bootstrap/scss/utilities/_flex.scss */
  .align-self-sm-auto {
    align-self: auto !important;
  }

  /* line 40, ../bootstrap/scss/utilities/_flex.scss */
  .align-self-sm-start {
    align-self: flex-start !important;
  }

  /* line 41, ../bootstrap/scss/utilities/_flex.scss */
  .align-self-sm-end {
    align-self: flex-end !important;
  }

  /* line 42, ../bootstrap/scss/utilities/_flex.scss */
  .align-self-sm-center {
    align-self: center !important;
  }

  /* line 43, ../bootstrap/scss/utilities/_flex.scss */
  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  /* line 44, ../bootstrap/scss/utilities/_flex.scss */
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {

  /* line 11, ../bootstrap/scss/utilities/_flex.scss */
  .flex-md-row {
    flex-direction: row !important;
  }

  /* line 12, ../bootstrap/scss/utilities/_flex.scss */
  .flex-md-column {
    flex-direction: column !important;
  }

  /* line 13, ../bootstrap/scss/utilities/_flex.scss */
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  /* line 14, ../bootstrap/scss/utilities/_flex.scss */
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  /* line 16, ../bootstrap/scss/utilities/_flex.scss */
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  /* line 17, ../bootstrap/scss/utilities/_flex.scss */
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  /* line 18, ../bootstrap/scss/utilities/_flex.scss */
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  /* line 20, ../bootstrap/scss/utilities/_flex.scss */
  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  /* line 21, ../bootstrap/scss/utilities/_flex.scss */
  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  /* line 22, ../bootstrap/scss/utilities/_flex.scss */
  .justify-content-md-center {
    justify-content: center !important;
  }

  /* line 23, ../bootstrap/scss/utilities/_flex.scss */
  .justify-content-md-between {
    justify-content: space-between !important;
  }

  /* line 24, ../bootstrap/scss/utilities/_flex.scss */
  .justify-content-md-around {
    justify-content: space-around !important;
  }

  /* line 26, ../bootstrap/scss/utilities/_flex.scss */
  .align-items-md-start {
    align-items: flex-start !important;
  }

  /* line 27, ../bootstrap/scss/utilities/_flex.scss */
  .align-items-md-end {
    align-items: flex-end !important;
  }

  /* line 28, ../bootstrap/scss/utilities/_flex.scss */
  .align-items-md-center {
    align-items: center !important;
  }

  /* line 29, ../bootstrap/scss/utilities/_flex.scss */
  .align-items-md-baseline {
    align-items: baseline !important;
  }

  /* line 30, ../bootstrap/scss/utilities/_flex.scss */
  .align-items-md-stretch {
    align-items: stretch !important;
  }

  /* line 32, ../bootstrap/scss/utilities/_flex.scss */
  .align-content-md-start {
    align-content: flex-start !important;
  }

  /* line 33, ../bootstrap/scss/utilities/_flex.scss */
  .align-content-md-end {
    align-content: flex-end !important;
  }

  /* line 34, ../bootstrap/scss/utilities/_flex.scss */
  .align-content-md-center {
    align-content: center !important;
  }

  /* line 35, ../bootstrap/scss/utilities/_flex.scss */
  .align-content-md-between {
    align-content: space-between !important;
  }

  /* line 36, ../bootstrap/scss/utilities/_flex.scss */
  .align-content-md-around {
    align-content: space-around !important;
  }

  /* line 37, ../bootstrap/scss/utilities/_flex.scss */
  .align-content-md-stretch {
    align-content: stretch !important;
  }

  /* line 39, ../bootstrap/scss/utilities/_flex.scss */
  .align-self-md-auto {
    align-self: auto !important;
  }

  /* line 40, ../bootstrap/scss/utilities/_flex.scss */
  .align-self-md-start {
    align-self: flex-start !important;
  }

  /* line 41, ../bootstrap/scss/utilities/_flex.scss */
  .align-self-md-end {
    align-self: flex-end !important;
  }

  /* line 42, ../bootstrap/scss/utilities/_flex.scss */
  .align-self-md-center {
    align-self: center !important;
  }

  /* line 43, ../bootstrap/scss/utilities/_flex.scss */
  .align-self-md-baseline {
    align-self: baseline !important;
  }

  /* line 44, ../bootstrap/scss/utilities/_flex.scss */
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {

  /* line 11, ../bootstrap/scss/utilities/_flex.scss */
  .flex-lg-row {
    flex-direction: row !important;
  }

  /* line 12, ../bootstrap/scss/utilities/_flex.scss */
  .flex-lg-column {
    flex-direction: column !important;
  }

  /* line 13, ../bootstrap/scss/utilities/_flex.scss */
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  /* line 14, ../bootstrap/scss/utilities/_flex.scss */
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  /* line 16, ../bootstrap/scss/utilities/_flex.scss */
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  /* line 17, ../bootstrap/scss/utilities/_flex.scss */
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  /* line 18, ../bootstrap/scss/utilities/_flex.scss */
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  /* line 20, ../bootstrap/scss/utilities/_flex.scss */
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  /* line 21, ../bootstrap/scss/utilities/_flex.scss */
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  /* line 22, ../bootstrap/scss/utilities/_flex.scss */
  .justify-content-lg-center {
    justify-content: center !important;
  }

  /* line 23, ../bootstrap/scss/utilities/_flex.scss */
  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  /* line 24, ../bootstrap/scss/utilities/_flex.scss */
  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  /* line 26, ../bootstrap/scss/utilities/_flex.scss */
  .align-items-lg-start {
    align-items: flex-start !important;
  }

  /* line 27, ../bootstrap/scss/utilities/_flex.scss */
  .align-items-lg-end {
    align-items: flex-end !important;
  }

  /* line 28, ../bootstrap/scss/utilities/_flex.scss */
  .align-items-lg-center {
    align-items: center !important;
  }

  /* line 29, ../bootstrap/scss/utilities/_flex.scss */
  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  /* line 30, ../bootstrap/scss/utilities/_flex.scss */
  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  /* line 32, ../bootstrap/scss/utilities/_flex.scss */
  .align-content-lg-start {
    align-content: flex-start !important;
  }

  /* line 33, ../bootstrap/scss/utilities/_flex.scss */
  .align-content-lg-end {
    align-content: flex-end !important;
  }

  /* line 34, ../bootstrap/scss/utilities/_flex.scss */
  .align-content-lg-center {
    align-content: center !important;
  }

  /* line 35, ../bootstrap/scss/utilities/_flex.scss */
  .align-content-lg-between {
    align-content: space-between !important;
  }

  /* line 36, ../bootstrap/scss/utilities/_flex.scss */
  .align-content-lg-around {
    align-content: space-around !important;
  }

  /* line 37, ../bootstrap/scss/utilities/_flex.scss */
  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  /* line 39, ../bootstrap/scss/utilities/_flex.scss */
  .align-self-lg-auto {
    align-self: auto !important;
  }

  /* line 40, ../bootstrap/scss/utilities/_flex.scss */
  .align-self-lg-start {
    align-self: flex-start !important;
  }

  /* line 41, ../bootstrap/scss/utilities/_flex.scss */
  .align-self-lg-end {
    align-self: flex-end !important;
  }

  /* line 42, ../bootstrap/scss/utilities/_flex.scss */
  .align-self-lg-center {
    align-self: center !important;
  }

  /* line 43, ../bootstrap/scss/utilities/_flex.scss */
  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  /* line 44, ../bootstrap/scss/utilities/_flex.scss */
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {

  /* line 11, ../bootstrap/scss/utilities/_flex.scss */
  .flex-xl-row {
    flex-direction: row !important;
  }

  /* line 12, ../bootstrap/scss/utilities/_flex.scss */
  .flex-xl-column {
    flex-direction: column !important;
  }

  /* line 13, ../bootstrap/scss/utilities/_flex.scss */
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  /* line 14, ../bootstrap/scss/utilities/_flex.scss */
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  /* line 16, ../bootstrap/scss/utilities/_flex.scss */
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  /* line 17, ../bootstrap/scss/utilities/_flex.scss */
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  /* line 18, ../bootstrap/scss/utilities/_flex.scss */
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  /* line 20, ../bootstrap/scss/utilities/_flex.scss */
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  /* line 21, ../bootstrap/scss/utilities/_flex.scss */
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  /* line 22, ../bootstrap/scss/utilities/_flex.scss */
  .justify-content-xl-center {
    justify-content: center !important;
  }

  /* line 23, ../bootstrap/scss/utilities/_flex.scss */
  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  /* line 24, ../bootstrap/scss/utilities/_flex.scss */
  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  /* line 26, ../bootstrap/scss/utilities/_flex.scss */
  .align-items-xl-start {
    align-items: flex-start !important;
  }

  /* line 27, ../bootstrap/scss/utilities/_flex.scss */
  .align-items-xl-end {
    align-items: flex-end !important;
  }

  /* line 28, ../bootstrap/scss/utilities/_flex.scss */
  .align-items-xl-center {
    align-items: center !important;
  }

  /* line 29, ../bootstrap/scss/utilities/_flex.scss */
  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  /* line 30, ../bootstrap/scss/utilities/_flex.scss */
  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  /* line 32, ../bootstrap/scss/utilities/_flex.scss */
  .align-content-xl-start {
    align-content: flex-start !important;
  }

  /* line 33, ../bootstrap/scss/utilities/_flex.scss */
  .align-content-xl-end {
    align-content: flex-end !important;
  }

  /* line 34, ../bootstrap/scss/utilities/_flex.scss */
  .align-content-xl-center {
    align-content: center !important;
  }

  /* line 35, ../bootstrap/scss/utilities/_flex.scss */
  .align-content-xl-between {
    align-content: space-between !important;
  }

  /* line 36, ../bootstrap/scss/utilities/_flex.scss */
  .align-content-xl-around {
    align-content: space-around !important;
  }

  /* line 37, ../bootstrap/scss/utilities/_flex.scss */
  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  /* line 39, ../bootstrap/scss/utilities/_flex.scss */
  .align-self-xl-auto {
    align-self: auto !important;
  }

  /* line 40, ../bootstrap/scss/utilities/_flex.scss */
  .align-self-xl-start {
    align-self: flex-start !important;
  }

  /* line 41, ../bootstrap/scss/utilities/_flex.scss */
  .align-self-xl-end {
    align-self: flex-end !important;
  }

  /* line 42, ../bootstrap/scss/utilities/_flex.scss */
  .align-self-xl-center {
    align-self: center !important;
  }

  /* line 43, ../bootstrap/scss/utilities/_flex.scss */
  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  /* line 44, ../bootstrap/scss/utilities/_flex.scss */
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

/* line 5, ../bootstrap/scss/utilities/_float.scss */
.float-left {
  float: left !important;
}

/* line 6, ../bootstrap/scss/utilities/_float.scss */
.float-right {
  float: right !important;
}

/* line 7, ../bootstrap/scss/utilities/_float.scss */
.float-none {
  float: none !important;
}

@media (min-width: 576px) {

  /* line 5, ../bootstrap/scss/utilities/_float.scss */
  .float-sm-left {
    float: left !important;
  }

  /* line 6, ../bootstrap/scss/utilities/_float.scss */
  .float-sm-right {
    float: right !important;
  }

  /* line 7, ../bootstrap/scss/utilities/_float.scss */
  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {

  /* line 5, ../bootstrap/scss/utilities/_float.scss */
  .float-md-left {
    float: left !important;
  }

  /* line 6, ../bootstrap/scss/utilities/_float.scss */
  .float-md-right {
    float: right !important;
  }

  /* line 7, ../bootstrap/scss/utilities/_float.scss */
  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {

  /* line 5, ../bootstrap/scss/utilities/_float.scss */
  .float-lg-left {
    float: left !important;
  }

  /* line 6, ../bootstrap/scss/utilities/_float.scss */
  .float-lg-right {
    float: right !important;
  }

  /* line 7, ../bootstrap/scss/utilities/_float.scss */
  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {

  /* line 5, ../bootstrap/scss/utilities/_float.scss */
  .float-xl-left {
    float: left !important;
  }

  /* line 6, ../bootstrap/scss/utilities/_float.scss */
  .float-xl-right {
    float: right !important;
  }

  /* line 7, ../bootstrap/scss/utilities/_float.scss */
  .float-xl-none {
    float: none !important;
  }
}

/* line 9, ../bootstrap/scss/utilities/_position.scss */
.position-static {
  position: static !important;
}

/* line 9, ../bootstrap/scss/utilities/_position.scss */
.position-relative {
  position: relative !important;
}

/* line 9, ../bootstrap/scss/utilities/_position.scss */
.position-absolute {
  position: absolute !important;
}

/* line 9, ../bootstrap/scss/utilities/_position.scss */
.position-fixed {
  position: fixed !important;
}

/* line 9, ../bootstrap/scss/utilities/_position.scss */
.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}

/* line 14, ../bootstrap/scss/utilities/_position.scss */
.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

/* line 22, ../bootstrap/scss/utilities/_position.scss */
.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports ((position: -webkit-sticky) or (position: sticky)) {

  /* line 30, ../bootstrap/scss/utilities/_position.scss */
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

/* line 5, ../bootstrap/scss/utilities/_screenreaders.scss */
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  -webkit-clip-path: inset(50%);
          clip-path: inset(50%);
  border: 0;
}

/* line 25, ../bootstrap/scss/mixins/_screen-reader.scss */
.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  -webkit-clip-path: none;
          clip-path: none;
}

/* line 7, ../bootstrap/scss/utilities/_sizing.scss */
.w-25 {
  width: 25% !important;
}

/* line 7, ../bootstrap/scss/utilities/_sizing.scss */
.w-50 {
  width: 50% !important;
}

/* line 7, ../bootstrap/scss/utilities/_sizing.scss */
.w-75 {
  width: 75% !important;
}

/* line 7, ../bootstrap/scss/utilities/_sizing.scss */
.w-100 {
  width: 100% !important;
}

/* line 7, ../bootstrap/scss/utilities/_sizing.scss */
.h-25 {
  height: 25% !important;
}

/* line 7, ../bootstrap/scss/utilities/_sizing.scss */
.h-50 {
  height: 50% !important;
}

/* line 7, ../bootstrap/scss/utilities/_sizing.scss */
.h-75 {
  height: 75% !important;
}

/* line 7, ../bootstrap/scss/utilities/_sizing.scss */
.h-100 {
  height: 100% !important;
}

/* line 11, ../bootstrap/scss/utilities/_sizing.scss */
.mw-100 {
  max-width: 100% !important;
}

/* line 12, ../bootstrap/scss/utilities/_sizing.scss */
.mh-100 {
  max-height: 100% !important;
}

/* line 12, ../bootstrap/scss/utilities/_spacing.scss */
.m-0 {
  margin: 0 !important;
}

/* line 13, ../bootstrap/scss/utilities/_spacing.scss */
.mt-0,
.my-0 {
  margin-top: 0 !important;
}

/* line 17, ../bootstrap/scss/utilities/_spacing.scss */
.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

/* line 21, ../bootstrap/scss/utilities/_spacing.scss */
.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

/* line 25, ../bootstrap/scss/utilities/_spacing.scss */
.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

/* line 12, ../bootstrap/scss/utilities/_spacing.scss */
.m-1 {
  margin: 0.25rem !important;
}

/* line 13, ../bootstrap/scss/utilities/_spacing.scss */
.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

/* line 17, ../bootstrap/scss/utilities/_spacing.scss */
.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

/* line 21, ../bootstrap/scss/utilities/_spacing.scss */
.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

/* line 25, ../bootstrap/scss/utilities/_spacing.scss */
.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

/* line 12, ../bootstrap/scss/utilities/_spacing.scss */
.m-2 {
  margin: 0.5rem !important;
}

/* line 13, ../bootstrap/scss/utilities/_spacing.scss */
.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

/* line 17, ../bootstrap/scss/utilities/_spacing.scss */
.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

/* line 21, ../bootstrap/scss/utilities/_spacing.scss */
.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

/* line 25, ../bootstrap/scss/utilities/_spacing.scss */
.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

/* line 12, ../bootstrap/scss/utilities/_spacing.scss */
.m-3 {
  margin: 1rem !important;
}

/* line 13, ../bootstrap/scss/utilities/_spacing.scss */
.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

/* line 17, ../bootstrap/scss/utilities/_spacing.scss */
.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

/* line 21, ../bootstrap/scss/utilities/_spacing.scss */
.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

/* line 25, ../bootstrap/scss/utilities/_spacing.scss */
.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

/* line 12, ../bootstrap/scss/utilities/_spacing.scss */
.m-4 {
  margin: 1.5rem !important;
}

/* line 13, ../bootstrap/scss/utilities/_spacing.scss */
.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

/* line 17, ../bootstrap/scss/utilities/_spacing.scss */
.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

/* line 21, ../bootstrap/scss/utilities/_spacing.scss */
.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

/* line 25, ../bootstrap/scss/utilities/_spacing.scss */
.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

/* line 12, ../bootstrap/scss/utilities/_spacing.scss */
.m-5 {
  margin: 3rem !important;
}

/* line 13, ../bootstrap/scss/utilities/_spacing.scss */
.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

/* line 17, ../bootstrap/scss/utilities/_spacing.scss */
.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

/* line 21, ../bootstrap/scss/utilities/_spacing.scss */
.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

/* line 25, ../bootstrap/scss/utilities/_spacing.scss */
.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

/* line 12, ../bootstrap/scss/utilities/_spacing.scss */
.p-0 {
  padding: 0 !important;
}

/* line 13, ../bootstrap/scss/utilities/_spacing.scss */
.pt-0,
.py-0 {
  padding-top: 0 !important;
}

/* line 17, ../bootstrap/scss/utilities/_spacing.scss */
.pr-0,
.px-0 {
  padding-right: 0 !important;
}

/* line 21, ../bootstrap/scss/utilities/_spacing.scss */
.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

/* line 25, ../bootstrap/scss/utilities/_spacing.scss */
.pl-0,
.px-0 {
  padding-left: 0 !important;
}

/* line 12, ../bootstrap/scss/utilities/_spacing.scss */
.p-1 {
  padding: 0.25rem !important;
}

/* line 13, ../bootstrap/scss/utilities/_spacing.scss */
.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

/* line 17, ../bootstrap/scss/utilities/_spacing.scss */
.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

/* line 21, ../bootstrap/scss/utilities/_spacing.scss */
.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

/* line 25, ../bootstrap/scss/utilities/_spacing.scss */
.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

/* line 12, ../bootstrap/scss/utilities/_spacing.scss */
.p-2 {
  padding: 0.5rem !important;
}

/* line 13, ../bootstrap/scss/utilities/_spacing.scss */
.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

/* line 17, ../bootstrap/scss/utilities/_spacing.scss */
.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

/* line 21, ../bootstrap/scss/utilities/_spacing.scss */
.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

/* line 25, ../bootstrap/scss/utilities/_spacing.scss */
.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

/* line 12, ../bootstrap/scss/utilities/_spacing.scss */
.p-3 {
  padding: 1rem !important;
}

/* line 13, ../bootstrap/scss/utilities/_spacing.scss */
.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

/* line 17, ../bootstrap/scss/utilities/_spacing.scss */
.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

/* line 21, ../bootstrap/scss/utilities/_spacing.scss */
.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

/* line 25, ../bootstrap/scss/utilities/_spacing.scss */
.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

/* line 12, ../bootstrap/scss/utilities/_spacing.scss */
.p-4 {
  padding: 1.5rem !important;
}

/* line 13, ../bootstrap/scss/utilities/_spacing.scss */
.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

/* line 17, ../bootstrap/scss/utilities/_spacing.scss */
.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

/* line 21, ../bootstrap/scss/utilities/_spacing.scss */
.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

/* line 25, ../bootstrap/scss/utilities/_spacing.scss */
.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

/* line 12, ../bootstrap/scss/utilities/_spacing.scss */
.p-5 {
  padding: 3rem !important;
}

/* line 13, ../bootstrap/scss/utilities/_spacing.scss */
.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

/* line 17, ../bootstrap/scss/utilities/_spacing.scss */
.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

/* line 21, ../bootstrap/scss/utilities/_spacing.scss */
.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

/* line 25, ../bootstrap/scss/utilities/_spacing.scss */
.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

/* line 33, ../bootstrap/scss/utilities/_spacing.scss */
.m-auto {
  margin: auto !important;
}

/* line 34, ../bootstrap/scss/utilities/_spacing.scss */
.mt-auto,
.my-auto {
  margin-top: auto !important;
}

/* line 38, ../bootstrap/scss/utilities/_spacing.scss */
.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

/* line 42, ../bootstrap/scss/utilities/_spacing.scss */
.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

/* line 46, ../bootstrap/scss/utilities/_spacing.scss */
.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {

  /* line 12, ../bootstrap/scss/utilities/_spacing.scss */
  .m-sm-0 {
    margin: 0 !important;
  }

  /* line 13, ../bootstrap/scss/utilities/_spacing.scss */
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }

  /* line 17, ../bootstrap/scss/utilities/_spacing.scss */
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }

  /* line 21, ../bootstrap/scss/utilities/_spacing.scss */
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }

  /* line 25, ../bootstrap/scss/utilities/_spacing.scss */
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }

  /* line 12, ../bootstrap/scss/utilities/_spacing.scss */
  .m-sm-1 {
    margin: 0.25rem !important;
  }

  /* line 13, ../bootstrap/scss/utilities/_spacing.scss */
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }

  /* line 17, ../bootstrap/scss/utilities/_spacing.scss */
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }

  /* line 21, ../bootstrap/scss/utilities/_spacing.scss */
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  /* line 25, ../bootstrap/scss/utilities/_spacing.scss */
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }

  /* line 12, ../bootstrap/scss/utilities/_spacing.scss */
  .m-sm-2 {
    margin: 0.5rem !important;
  }

  /* line 13, ../bootstrap/scss/utilities/_spacing.scss */
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }

  /* line 17, ../bootstrap/scss/utilities/_spacing.scss */
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }

  /* line 21, ../bootstrap/scss/utilities/_spacing.scss */
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  /* line 25, ../bootstrap/scss/utilities/_spacing.scss */
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }

  /* line 12, ../bootstrap/scss/utilities/_spacing.scss */
  .m-sm-3 {
    margin: 1rem !important;
  }

  /* line 13, ../bootstrap/scss/utilities/_spacing.scss */
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }

  /* line 17, ../bootstrap/scss/utilities/_spacing.scss */
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }

  /* line 21, ../bootstrap/scss/utilities/_spacing.scss */
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }

  /* line 25, ../bootstrap/scss/utilities/_spacing.scss */
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }

  /* line 12, ../bootstrap/scss/utilities/_spacing.scss */
  .m-sm-4 {
    margin: 1.5rem !important;
  }

  /* line 13, ../bootstrap/scss/utilities/_spacing.scss */
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }

  /* line 17, ../bootstrap/scss/utilities/_spacing.scss */
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }

  /* line 21, ../bootstrap/scss/utilities/_spacing.scss */
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  /* line 25, ../bootstrap/scss/utilities/_spacing.scss */
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }

  /* line 12, ../bootstrap/scss/utilities/_spacing.scss */
  .m-sm-5 {
    margin: 3rem !important;
  }

  /* line 13, ../bootstrap/scss/utilities/_spacing.scss */
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }

  /* line 17, ../bootstrap/scss/utilities/_spacing.scss */
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }

  /* line 21, ../bootstrap/scss/utilities/_spacing.scss */
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }

  /* line 25, ../bootstrap/scss/utilities/_spacing.scss */
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }

  /* line 12, ../bootstrap/scss/utilities/_spacing.scss */
  .p-sm-0 {
    padding: 0 !important;
  }

  /* line 13, ../bootstrap/scss/utilities/_spacing.scss */
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }

  /* line 17, ../bootstrap/scss/utilities/_spacing.scss */
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }

  /* line 21, ../bootstrap/scss/utilities/_spacing.scss */
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }

  /* line 25, ../bootstrap/scss/utilities/_spacing.scss */
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }

  /* line 12, ../bootstrap/scss/utilities/_spacing.scss */
  .p-sm-1 {
    padding: 0.25rem !important;
  }

  /* line 13, ../bootstrap/scss/utilities/_spacing.scss */
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }

  /* line 17, ../bootstrap/scss/utilities/_spacing.scss */
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }

  /* line 21, ../bootstrap/scss/utilities/_spacing.scss */
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  /* line 25, ../bootstrap/scss/utilities/_spacing.scss */
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }

  /* line 12, ../bootstrap/scss/utilities/_spacing.scss */
  .p-sm-2 {
    padding: 0.5rem !important;
  }

  /* line 13, ../bootstrap/scss/utilities/_spacing.scss */
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }

  /* line 17, ../bootstrap/scss/utilities/_spacing.scss */
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }

  /* line 21, ../bootstrap/scss/utilities/_spacing.scss */
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  /* line 25, ../bootstrap/scss/utilities/_spacing.scss */
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }

  /* line 12, ../bootstrap/scss/utilities/_spacing.scss */
  .p-sm-3 {
    padding: 1rem !important;
  }

  /* line 13, ../bootstrap/scss/utilities/_spacing.scss */
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }

  /* line 17, ../bootstrap/scss/utilities/_spacing.scss */
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }

  /* line 21, ../bootstrap/scss/utilities/_spacing.scss */
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }

  /* line 25, ../bootstrap/scss/utilities/_spacing.scss */
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }

  /* line 12, ../bootstrap/scss/utilities/_spacing.scss */
  .p-sm-4 {
    padding: 1.5rem !important;
  }

  /* line 13, ../bootstrap/scss/utilities/_spacing.scss */
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }

  /* line 17, ../bootstrap/scss/utilities/_spacing.scss */
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }

  /* line 21, ../bootstrap/scss/utilities/_spacing.scss */
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  /* line 25, ../bootstrap/scss/utilities/_spacing.scss */
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }

  /* line 12, ../bootstrap/scss/utilities/_spacing.scss */
  .p-sm-5 {
    padding: 3rem !important;
  }

  /* line 13, ../bootstrap/scss/utilities/_spacing.scss */
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }

  /* line 17, ../bootstrap/scss/utilities/_spacing.scss */
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }

  /* line 21, ../bootstrap/scss/utilities/_spacing.scss */
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }

  /* line 25, ../bootstrap/scss/utilities/_spacing.scss */
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }

  /* line 33, ../bootstrap/scss/utilities/_spacing.scss */
  .m-sm-auto {
    margin: auto !important;
  }

  /* line 34, ../bootstrap/scss/utilities/_spacing.scss */
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }

  /* line 38, ../bootstrap/scss/utilities/_spacing.scss */
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }

  /* line 42, ../bootstrap/scss/utilities/_spacing.scss */
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }

  /* line 46, ../bootstrap/scss/utilities/_spacing.scss */
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {

  /* line 12, ../bootstrap/scss/utilities/_spacing.scss */
  .m-md-0 {
    margin: 0 !important;
  }

  /* line 13, ../bootstrap/scss/utilities/_spacing.scss */
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }

  /* line 17, ../bootstrap/scss/utilities/_spacing.scss */
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }

  /* line 21, ../bootstrap/scss/utilities/_spacing.scss */
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }

  /* line 25, ../bootstrap/scss/utilities/_spacing.scss */
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }

  /* line 12, ../bootstrap/scss/utilities/_spacing.scss */
  .m-md-1 {
    margin: 0.25rem !important;
  }

  /* line 13, ../bootstrap/scss/utilities/_spacing.scss */
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }

  /* line 17, ../bootstrap/scss/utilities/_spacing.scss */
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }

  /* line 21, ../bootstrap/scss/utilities/_spacing.scss */
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }

  /* line 25, ../bootstrap/scss/utilities/_spacing.scss */
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }

  /* line 12, ../bootstrap/scss/utilities/_spacing.scss */
  .m-md-2 {
    margin: 0.5rem !important;
  }

  /* line 13, ../bootstrap/scss/utilities/_spacing.scss */
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }

  /* line 17, ../bootstrap/scss/utilities/_spacing.scss */
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }

  /* line 21, ../bootstrap/scss/utilities/_spacing.scss */
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }

  /* line 25, ../bootstrap/scss/utilities/_spacing.scss */
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }

  /* line 12, ../bootstrap/scss/utilities/_spacing.scss */
  .m-md-3 {
    margin: 1rem !important;
  }

  /* line 13, ../bootstrap/scss/utilities/_spacing.scss */
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }

  /* line 17, ../bootstrap/scss/utilities/_spacing.scss */
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }

  /* line 21, ../bootstrap/scss/utilities/_spacing.scss */
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }

  /* line 25, ../bootstrap/scss/utilities/_spacing.scss */
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }

  /* line 12, ../bootstrap/scss/utilities/_spacing.scss */
  .m-md-4 {
    margin: 1.5rem !important;
  }

  /* line 13, ../bootstrap/scss/utilities/_spacing.scss */
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }

  /* line 17, ../bootstrap/scss/utilities/_spacing.scss */
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }

  /* line 21, ../bootstrap/scss/utilities/_spacing.scss */
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }

  /* line 25, ../bootstrap/scss/utilities/_spacing.scss */
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }

  /* line 12, ../bootstrap/scss/utilities/_spacing.scss */
  .m-md-5 {
    margin: 3rem !important;
  }

  /* line 13, ../bootstrap/scss/utilities/_spacing.scss */
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }

  /* line 17, ../bootstrap/scss/utilities/_spacing.scss */
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }

  /* line 21, ../bootstrap/scss/utilities/_spacing.scss */
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }

  /* line 25, ../bootstrap/scss/utilities/_spacing.scss */
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }

  /* line 12, ../bootstrap/scss/utilities/_spacing.scss */
  .p-md-0 {
    padding: 0 !important;
  }

  /* line 13, ../bootstrap/scss/utilities/_spacing.scss */
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }

  /* line 17, ../bootstrap/scss/utilities/_spacing.scss */
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }

  /* line 21, ../bootstrap/scss/utilities/_spacing.scss */
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }

  /* line 25, ../bootstrap/scss/utilities/_spacing.scss */
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }

  /* line 12, ../bootstrap/scss/utilities/_spacing.scss */
  .p-md-1 {
    padding: 0.25rem !important;
  }

  /* line 13, ../bootstrap/scss/utilities/_spacing.scss */
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }

  /* line 17, ../bootstrap/scss/utilities/_spacing.scss */
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }

  /* line 21, ../bootstrap/scss/utilities/_spacing.scss */
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }

  /* line 25, ../bootstrap/scss/utilities/_spacing.scss */
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }

  /* line 12, ../bootstrap/scss/utilities/_spacing.scss */
  .p-md-2 {
    padding: 0.5rem !important;
  }

  /* line 13, ../bootstrap/scss/utilities/_spacing.scss */
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }

  /* line 17, ../bootstrap/scss/utilities/_spacing.scss */
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }

  /* line 21, ../bootstrap/scss/utilities/_spacing.scss */
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }

  /* line 25, ../bootstrap/scss/utilities/_spacing.scss */
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }

  /* line 12, ../bootstrap/scss/utilities/_spacing.scss */
  .p-md-3 {
    padding: 1rem !important;
  }

  /* line 13, ../bootstrap/scss/utilities/_spacing.scss */
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }

  /* line 17, ../bootstrap/scss/utilities/_spacing.scss */
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }

  /* line 21, ../bootstrap/scss/utilities/_spacing.scss */
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }

  /* line 25, ../bootstrap/scss/utilities/_spacing.scss */
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }

  /* line 12, ../bootstrap/scss/utilities/_spacing.scss */
  .p-md-4 {
    padding: 1.5rem !important;
  }

  /* line 13, ../bootstrap/scss/utilities/_spacing.scss */
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }

  /* line 17, ../bootstrap/scss/utilities/_spacing.scss */
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }

  /* line 21, ../bootstrap/scss/utilities/_spacing.scss */
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }

  /* line 25, ../bootstrap/scss/utilities/_spacing.scss */
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }

  /* line 12, ../bootstrap/scss/utilities/_spacing.scss */
  .p-md-5 {
    padding: 3rem !important;
  }

  /* line 13, ../bootstrap/scss/utilities/_spacing.scss */
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }

  /* line 17, ../bootstrap/scss/utilities/_spacing.scss */
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }

  /* line 21, ../bootstrap/scss/utilities/_spacing.scss */
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }

  /* line 25, ../bootstrap/scss/utilities/_spacing.scss */
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }

  /* line 33, ../bootstrap/scss/utilities/_spacing.scss */
  .m-md-auto {
    margin: auto !important;
  }

  /* line 34, ../bootstrap/scss/utilities/_spacing.scss */
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }

  /* line 38, ../bootstrap/scss/utilities/_spacing.scss */
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }

  /* line 42, ../bootstrap/scss/utilities/_spacing.scss */
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }

  /* line 46, ../bootstrap/scss/utilities/_spacing.scss */
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {

  /* line 12, ../bootstrap/scss/utilities/_spacing.scss */
  .m-lg-0 {
    margin: 0 !important;
  }

  /* line 13, ../bootstrap/scss/utilities/_spacing.scss */
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }

  /* line 17, ../bootstrap/scss/utilities/_spacing.scss */
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }

  /* line 21, ../bootstrap/scss/utilities/_spacing.scss */
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }

  /* line 25, ../bootstrap/scss/utilities/_spacing.scss */
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }

  /* line 12, ../bootstrap/scss/utilities/_spacing.scss */
  .m-lg-1 {
    margin: 0.25rem !important;
  }

  /* line 13, ../bootstrap/scss/utilities/_spacing.scss */
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }

  /* line 17, ../bootstrap/scss/utilities/_spacing.scss */
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }

  /* line 21, ../bootstrap/scss/utilities/_spacing.scss */
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  /* line 25, ../bootstrap/scss/utilities/_spacing.scss */
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }

  /* line 12, ../bootstrap/scss/utilities/_spacing.scss */
  .m-lg-2 {
    margin: 0.5rem !important;
  }

  /* line 13, ../bootstrap/scss/utilities/_spacing.scss */
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }

  /* line 17, ../bootstrap/scss/utilities/_spacing.scss */
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }

  /* line 21, ../bootstrap/scss/utilities/_spacing.scss */
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  /* line 25, ../bootstrap/scss/utilities/_spacing.scss */
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }

  /* line 12, ../bootstrap/scss/utilities/_spacing.scss */
  .m-lg-3 {
    margin: 1rem !important;
  }

  /* line 13, ../bootstrap/scss/utilities/_spacing.scss */
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }

  /* line 17, ../bootstrap/scss/utilities/_spacing.scss */
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }

  /* line 21, ../bootstrap/scss/utilities/_spacing.scss */
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }

  /* line 25, ../bootstrap/scss/utilities/_spacing.scss */
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }

  /* line 12, ../bootstrap/scss/utilities/_spacing.scss */
  .m-lg-4 {
    margin: 1.5rem !important;
  }

  /* line 13, ../bootstrap/scss/utilities/_spacing.scss */
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }

  /* line 17, ../bootstrap/scss/utilities/_spacing.scss */
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }

  /* line 21, ../bootstrap/scss/utilities/_spacing.scss */
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  /* line 25, ../bootstrap/scss/utilities/_spacing.scss */
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }

  /* line 12, ../bootstrap/scss/utilities/_spacing.scss */
  .m-lg-5 {
    margin: 3rem !important;
  }

  /* line 13, ../bootstrap/scss/utilities/_spacing.scss */
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }

  /* line 17, ../bootstrap/scss/utilities/_spacing.scss */
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }

  /* line 21, ../bootstrap/scss/utilities/_spacing.scss */
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }

  /* line 25, ../bootstrap/scss/utilities/_spacing.scss */
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }

  /* line 12, ../bootstrap/scss/utilities/_spacing.scss */
  .p-lg-0 {
    padding: 0 !important;
  }

  /* line 13, ../bootstrap/scss/utilities/_spacing.scss */
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }

  /* line 17, ../bootstrap/scss/utilities/_spacing.scss */
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }

  /* line 21, ../bootstrap/scss/utilities/_spacing.scss */
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }

  /* line 25, ../bootstrap/scss/utilities/_spacing.scss */
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }

  /* line 12, ../bootstrap/scss/utilities/_spacing.scss */
  .p-lg-1 {
    padding: 0.25rem !important;
  }

  /* line 13, ../bootstrap/scss/utilities/_spacing.scss */
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }

  /* line 17, ../bootstrap/scss/utilities/_spacing.scss */
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }

  /* line 21, ../bootstrap/scss/utilities/_spacing.scss */
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  /* line 25, ../bootstrap/scss/utilities/_spacing.scss */
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }

  /* line 12, ../bootstrap/scss/utilities/_spacing.scss */
  .p-lg-2 {
    padding: 0.5rem !important;
  }

  /* line 13, ../bootstrap/scss/utilities/_spacing.scss */
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }

  /* line 17, ../bootstrap/scss/utilities/_spacing.scss */
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }

  /* line 21, ../bootstrap/scss/utilities/_spacing.scss */
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  /* line 25, ../bootstrap/scss/utilities/_spacing.scss */
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }

  /* line 12, ../bootstrap/scss/utilities/_spacing.scss */
  .p-lg-3 {
    padding: 1rem !important;
  }

  /* line 13, ../bootstrap/scss/utilities/_spacing.scss */
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }

  /* line 17, ../bootstrap/scss/utilities/_spacing.scss */
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }

  /* line 21, ../bootstrap/scss/utilities/_spacing.scss */
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }

  /* line 25, ../bootstrap/scss/utilities/_spacing.scss */
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }

  /* line 12, ../bootstrap/scss/utilities/_spacing.scss */
  .p-lg-4 {
    padding: 1.5rem !important;
  }

  /* line 13, ../bootstrap/scss/utilities/_spacing.scss */
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }

  /* line 17, ../bootstrap/scss/utilities/_spacing.scss */
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }

  /* line 21, ../bootstrap/scss/utilities/_spacing.scss */
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  /* line 25, ../bootstrap/scss/utilities/_spacing.scss */
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }

  /* line 12, ../bootstrap/scss/utilities/_spacing.scss */
  .p-lg-5 {
    padding: 3rem !important;
  }

  /* line 13, ../bootstrap/scss/utilities/_spacing.scss */
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }

  /* line 17, ../bootstrap/scss/utilities/_spacing.scss */
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }

  /* line 21, ../bootstrap/scss/utilities/_spacing.scss */
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }

  /* line 25, ../bootstrap/scss/utilities/_spacing.scss */
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }

  /* line 33, ../bootstrap/scss/utilities/_spacing.scss */
  .m-lg-auto {
    margin: auto !important;
  }

  /* line 34, ../bootstrap/scss/utilities/_spacing.scss */
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }

  /* line 38, ../bootstrap/scss/utilities/_spacing.scss */
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }

  /* line 42, ../bootstrap/scss/utilities/_spacing.scss */
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }

  /* line 46, ../bootstrap/scss/utilities/_spacing.scss */
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {

  /* line 12, ../bootstrap/scss/utilities/_spacing.scss */
  .m-xl-0 {
    margin: 0 !important;
  }

  /* line 13, ../bootstrap/scss/utilities/_spacing.scss */
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }

  /* line 17, ../bootstrap/scss/utilities/_spacing.scss */
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }

  /* line 21, ../bootstrap/scss/utilities/_spacing.scss */
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }

  /* line 25, ../bootstrap/scss/utilities/_spacing.scss */
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }

  /* line 12, ../bootstrap/scss/utilities/_spacing.scss */
  .m-xl-1 {
    margin: 0.25rem !important;
  }

  /* line 13, ../bootstrap/scss/utilities/_spacing.scss */
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }

  /* line 17, ../bootstrap/scss/utilities/_spacing.scss */
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }

  /* line 21, ../bootstrap/scss/utilities/_spacing.scss */
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  /* line 25, ../bootstrap/scss/utilities/_spacing.scss */
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }

  /* line 12, ../bootstrap/scss/utilities/_spacing.scss */
  .m-xl-2 {
    margin: 0.5rem !important;
  }

  /* line 13, ../bootstrap/scss/utilities/_spacing.scss */
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }

  /* line 17, ../bootstrap/scss/utilities/_spacing.scss */
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }

  /* line 21, ../bootstrap/scss/utilities/_spacing.scss */
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  /* line 25, ../bootstrap/scss/utilities/_spacing.scss */
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }

  /* line 12, ../bootstrap/scss/utilities/_spacing.scss */
  .m-xl-3 {
    margin: 1rem !important;
  }

  /* line 13, ../bootstrap/scss/utilities/_spacing.scss */
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }

  /* line 17, ../bootstrap/scss/utilities/_spacing.scss */
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }

  /* line 21, ../bootstrap/scss/utilities/_spacing.scss */
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }

  /* line 25, ../bootstrap/scss/utilities/_spacing.scss */
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }

  /* line 12, ../bootstrap/scss/utilities/_spacing.scss */
  .m-xl-4 {
    margin: 1.5rem !important;
  }

  /* line 13, ../bootstrap/scss/utilities/_spacing.scss */
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }

  /* line 17, ../bootstrap/scss/utilities/_spacing.scss */
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }

  /* line 21, ../bootstrap/scss/utilities/_spacing.scss */
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  /* line 25, ../bootstrap/scss/utilities/_spacing.scss */
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }

  /* line 12, ../bootstrap/scss/utilities/_spacing.scss */
  .m-xl-5 {
    margin: 3rem !important;
  }

  /* line 13, ../bootstrap/scss/utilities/_spacing.scss */
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }

  /* line 17, ../bootstrap/scss/utilities/_spacing.scss */
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }

  /* line 21, ../bootstrap/scss/utilities/_spacing.scss */
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }

  /* line 25, ../bootstrap/scss/utilities/_spacing.scss */
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }

  /* line 12, ../bootstrap/scss/utilities/_spacing.scss */
  .p-xl-0 {
    padding: 0 !important;
  }

  /* line 13, ../bootstrap/scss/utilities/_spacing.scss */
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }

  /* line 17, ../bootstrap/scss/utilities/_spacing.scss */
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }

  /* line 21, ../bootstrap/scss/utilities/_spacing.scss */
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }

  /* line 25, ../bootstrap/scss/utilities/_spacing.scss */
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }

  /* line 12, ../bootstrap/scss/utilities/_spacing.scss */
  .p-xl-1 {
    padding: 0.25rem !important;
  }

  /* line 13, ../bootstrap/scss/utilities/_spacing.scss */
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }

  /* line 17, ../bootstrap/scss/utilities/_spacing.scss */
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }

  /* line 21, ../bootstrap/scss/utilities/_spacing.scss */
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  /* line 25, ../bootstrap/scss/utilities/_spacing.scss */
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }

  /* line 12, ../bootstrap/scss/utilities/_spacing.scss */
  .p-xl-2 {
    padding: 0.5rem !important;
  }

  /* line 13, ../bootstrap/scss/utilities/_spacing.scss */
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }

  /* line 17, ../bootstrap/scss/utilities/_spacing.scss */
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }

  /* line 21, ../bootstrap/scss/utilities/_spacing.scss */
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  /* line 25, ../bootstrap/scss/utilities/_spacing.scss */
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }

  /* line 12, ../bootstrap/scss/utilities/_spacing.scss */
  .p-xl-3 {
    padding: 1rem !important;
  }

  /* line 13, ../bootstrap/scss/utilities/_spacing.scss */
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }

  /* line 17, ../bootstrap/scss/utilities/_spacing.scss */
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }

  /* line 21, ../bootstrap/scss/utilities/_spacing.scss */
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }

  /* line 25, ../bootstrap/scss/utilities/_spacing.scss */
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }

  /* line 12, ../bootstrap/scss/utilities/_spacing.scss */
  .p-xl-4 {
    padding: 1.5rem !important;
  }

  /* line 13, ../bootstrap/scss/utilities/_spacing.scss */
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }

  /* line 17, ../bootstrap/scss/utilities/_spacing.scss */
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }

  /* line 21, ../bootstrap/scss/utilities/_spacing.scss */
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  /* line 25, ../bootstrap/scss/utilities/_spacing.scss */
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }

  /* line 12, ../bootstrap/scss/utilities/_spacing.scss */
  .p-xl-5 {
    padding: 3rem !important;
  }

  /* line 13, ../bootstrap/scss/utilities/_spacing.scss */
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }

  /* line 17, ../bootstrap/scss/utilities/_spacing.scss */
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }

  /* line 21, ../bootstrap/scss/utilities/_spacing.scss */
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }

  /* line 25, ../bootstrap/scss/utilities/_spacing.scss */
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }

  /* line 33, ../bootstrap/scss/utilities/_spacing.scss */
  .m-xl-auto {
    margin: auto !important;
  }

  /* line 34, ../bootstrap/scss/utilities/_spacing.scss */
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }

  /* line 38, ../bootstrap/scss/utilities/_spacing.scss */
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }

  /* line 42, ../bootstrap/scss/utilities/_spacing.scss */
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }

  /* line 46, ../bootstrap/scss/utilities/_spacing.scss */
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

/* line 9, ../bootstrap/scss/utilities/_text.scss */
.text-justify {
  text-align: justify !important;
}

/* line 10, ../bootstrap/scss/utilities/_text.scss */
.text-nowrap {
  white-space: nowrap !important;
}

/* line 11, ../bootstrap/scss/utilities/_text.scss */
.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* line 19, ../bootstrap/scss/utilities/_text.scss */
.text-left {
  text-align: left !important;
}

/* line 20, ../bootstrap/scss/utilities/_text.scss */
.text-right {
  text-align: right !important;
}

/* line 21, ../bootstrap/scss/utilities/_text.scss */
.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {

  /* line 19, ../bootstrap/scss/utilities/_text.scss */
  .text-sm-left {
    text-align: left !important;
  }

  /* line 20, ../bootstrap/scss/utilities/_text.scss */
  .text-sm-right {
    text-align: right !important;
  }

  /* line 21, ../bootstrap/scss/utilities/_text.scss */
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {

  /* line 19, ../bootstrap/scss/utilities/_text.scss */
  .text-md-left {
    text-align: left !important;
  }

  /* line 20, ../bootstrap/scss/utilities/_text.scss */
  .text-md-right {
    text-align: right !important;
  }

  /* line 21, ../bootstrap/scss/utilities/_text.scss */
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {

  /* line 19, ../bootstrap/scss/utilities/_text.scss */
  .text-lg-left {
    text-align: left !important;
  }

  /* line 20, ../bootstrap/scss/utilities/_text.scss */
  .text-lg-right {
    text-align: right !important;
  }

  /* line 21, ../bootstrap/scss/utilities/_text.scss */
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {

  /* line 19, ../bootstrap/scss/utilities/_text.scss */
  .text-xl-left {
    text-align: left !important;
  }

  /* line 20, ../bootstrap/scss/utilities/_text.scss */
  .text-xl-right {
    text-align: right !important;
  }

  /* line 21, ../bootstrap/scss/utilities/_text.scss */
  .text-xl-center {
    text-align: center !important;
  }
}

/* line 27, ../bootstrap/scss/utilities/_text.scss */
.text-lowercase {
  text-transform: lowercase !important;
}

/* line 28, ../bootstrap/scss/utilities/_text.scss */
.text-uppercase {
  text-transform: uppercase !important;
}

/* line 29, ../bootstrap/scss/utilities/_text.scss */
.text-capitalize {
  text-transform: capitalize !important;
}

/* line 33, ../bootstrap/scss/utilities/_text.scss */
.font-weight-light {
  font-weight: 300 !important;
}

/* line 34, ../bootstrap/scss/utilities/_text.scss */
.font-weight-normal {
  font-weight: 400 !important;
}

/* line 35, ../bootstrap/scss/utilities/_text.scss */
.font-weight-bold {
  font-weight: 700 !important;
}

/* line 36, ../bootstrap/scss/utilities/_text.scss */
.font-italic {
  font-style: italic !important;
}

/* line 40, ../bootstrap/scss/utilities/_text.scss */
.text-white {
  color: #fff !important;
}

/* line 6, ../bootstrap/scss/mixins/_text-emphasis.scss */
.text-primary {
  color: #007bff !important;
}

/* line 19, ../bootstrap/scss/mixins/_hover.scss */
a.text-primary:hover,
a.text-primary:focus {
  color: #0062cc !important;
}

/* line 6, ../bootstrap/scss/mixins/_text-emphasis.scss */
.text-secondary {
  color: #6c757d !important;
}

/* line 19, ../bootstrap/scss/mixins/_hover.scss */
a.text-secondary:hover,
a.text-secondary:focus {
  color: #545b62 !important;
}

/* line 6, ../bootstrap/scss/mixins/_text-emphasis.scss */
.text-success {
  color: #28a745 !important;
}

/* line 19, ../bootstrap/scss/mixins/_hover.scss */
a.text-success:hover,
a.text-success:focus {
  color: #1e7e34 !important;
}

/* line 6, ../bootstrap/scss/mixins/_text-emphasis.scss */
.text-info {
  color: #17a2b8 !important;
}

/* line 19, ../bootstrap/scss/mixins/_hover.scss */
a.text-info:hover,
a.text-info:focus {
  color: #117a8b !important;
}

/* line 6, ../bootstrap/scss/mixins/_text-emphasis.scss */
.text-warning {
  color: #feb325 !important;
}

/* line 19, ../bootstrap/scss/mixins/_hover.scss */
a.text-warning:hover,
a.text-warning:focus {
  color: #ef9d01 !important;
}

/* line 6, ../bootstrap/scss/mixins/_text-emphasis.scss */
.text-danger {
  color: #dc3545 !important;
}

/* line 19, ../bootstrap/scss/mixins/_hover.scss */
a.text-danger:hover,
a.text-danger:focus {
  color: #bd2130 !important;
}

/* line 6, ../bootstrap/scss/mixins/_text-emphasis.scss */
.text-light {
  color: #f8f9fa !important;
}

/* line 19, ../bootstrap/scss/mixins/_hover.scss */
a.text-light:hover,
a.text-light:focus {
  color: #dae0e5 !important;
}

/* line 6, ../bootstrap/scss/mixins/_text-emphasis.scss */
.text-dark {
  color: #343a40 !important;
}

/* line 19, ../bootstrap/scss/mixins/_hover.scss */
a.text-dark:hover,
a.text-dark:focus {
  color: #1d2124 !important;
}

/* line 46, ../bootstrap/scss/utilities/_text.scss */
.text-muted {
  color: #6c757d !important;
}

/* line 50, ../bootstrap/scss/utilities/_text.scss */
.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

/* line 5, ../bootstrap/scss/utilities/_visibility.scss */
.visible {
  visibility: visible !important;
}

/* line 9, ../bootstrap/scss/utilities/_visibility.scss */
.invisible {
  visibility: hidden !important;
}

/* line 8, ../bootstrap/scss/_modal.scss */
.modal-open {
  overflow: hidden;
}

/* line 13, ../bootstrap/scss/_modal.scss */
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0;
}

/* line 29, ../bootstrap/scss/_modal.scss */
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

/* line 36, ../bootstrap/scss/_modal.scss */
.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

/* line 44, ../bootstrap/scss/_modal.scss */
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -25%);
}

/* line 48, ../bootstrap/scss/_modal.scss */
.modal.show .modal-dialog {
  transform: translate(0, 0);
}

/* line 53, ../bootstrap/scss/_modal.scss */
.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - (0.5rem * 2));
}

/* line 60, ../bootstrap/scss/_modal.scss */
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

/* line 77, ../bootstrap/scss/_modal.scss */
.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000;
}

/* line 87, ../bootstrap/scss/_modal.scss */
.modal-backdrop.fade {
  opacity: 0;
}

/* line 88, ../bootstrap/scss/_modal.scss */
.modal-backdrop.show {
  opacity: 0.5;
}

/* line 93, ../bootstrap/scss/_modal.scss */
.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

/* line 101, ../bootstrap/scss/_modal.scss */
.modal-header .close {
  padding: 1rem;
  margin: -1rem -1rem -1rem auto;
}

/* line 109, ../bootstrap/scss/_modal.scss */
.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

/* line 116, ../bootstrap/scss/_modal.scss */
.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

/* line 125, ../bootstrap/scss/_modal.scss */
.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #e9ecef;
}

/* line 133, ../bootstrap/scss/_modal.scss */
.modal-footer> :not(:first-child) {
  margin-left: 0.25rem;
}

/* line 134, ../bootstrap/scss/_modal.scss */
.modal-footer> :not(:last-child) {
  margin-right: 0.25rem;
}

/* line 138, ../bootstrap/scss/_modal.scss */
.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {

  /* line 149, ../bootstrap/scss/_modal.scss */
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  /* line 154, ../bootstrap/scss/_modal.scss */
  .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2));
  }

  /* line 162, ../bootstrap/scss/_modal.scss */
  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {

  /* line 167, ../bootstrap/scss/_modal.scss */
  .modal-lg {
    max-width: 800px;
  }
}

@media (max-width: 991px) {

  /* line 16, ../sass/style.scss */
  html {
    font-size: 14px;
  }
}

@media (max-width: 767px) {

  /* line 16, ../sass/style.scss */
  html {
    font-size: 12px;
  }
}

/* line 24, ../sass/style.scss */
body {
  color: #4d4d4d;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 400;
}

/* line 29, ../sass/style.scss */
img {
  max-width: 100%;
}

/* line 32, ../sass/style.scss */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  color: #005eaf;
}

/* line 36, ../sass/style.scss */
h1 {
  font-size: 2.875rem;
}

/* line 40, ../sass/style.scss */
h3 {
  font-size: 1.875rem;
  margin-bottom: 1em;
  letter-spacing: 1.5px;
}

/* line 45, ../sass/style.scss */
h4 {
  font-weight: 600;
  letter-spacing: 1.2px;
}

/* line 49, ../sass/style.scss */
h5 {
  font-size: 1.375rem;
  font-weight: 600;
  letter-spacing: 1.1px;
}

/* line 54, ../sass/style.scss */
h6 {
  font-size: 1.125rem;
  letter-spacing: 0.9px;
  font-weight: 600;
}

/* line 59, ../sass/style.scss */
a {
  color: #005eaf;
}

/* line 61, ../sass/style.scss */
a:hover {
  text-decoration: none;
}

/* line 64, ../sass/style.scss */
a:focus {
  outline: none;
}

/* line 69, ../sass/style.scss */
select {
  -webkit-appearance: none;
  -moz-appearance: none;
}

/* line 73, ../sass/style.scss */
select::-ms-expand {
  display: none;
}

/* line 76, ../sass/style.scss */
.container-586 {
  max-width: 586px;
  margin: 0 auto;
}

.container-750 {
  max-width: 750px;
  margin: 0 auto;
}

/* line 80, ../sass/style.scss */
.container-686 {
  max-width: 686px;
  margin: 0 auto;
}

/* line 84, ../sass/style.scss */
.container-890 {
  max-width: 890px;
  margin: 0 auto;
}

/* line 89, ../sass/style.scss */
.white-box {
  background: #fff;
  padding: 2rem 2.5rem;
  box-shadow: 0px 2px 50px rgba(0, 52, 75, 0.26);
}

/* line 94, ../sass/style.scss */
.form-group {
  position: relative;
}

/* line 96, ../sass/style.scss */
.form-group>label {
  font-size: 17px;
  font-weight: 600;
}

/* line 100, ../sass/style.scss */
.form-group .form-control {
  border: none;
  height: auto !important;
  padding: 17px 0;
  border-bottom: solid 1px rgba(121, 121, 121, 0.7);
  border-radius: 0;
  box-shadow: none !important;
  color: rgba(0, 0, 0, 0.5);
  font-size: 1.125rem;
}

.form-group .form-control-checkout-acknowledge {
  border: none;
  height: auto !important;
  border-bottom: solid 1px rgba(121, 121, 121, 0.7);
  border-radius: 0;
  box-shadow: none !important;
  color: rgba(0, 0, 0, 0.5);
  font-size: 1.125rem;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
}

/* line 109, ../sass/style.scss */
.form-group .form-control+label.material-design {
  line-height: 1;
  letter-spacing: 0.9px;
  text-align: left;
  color: rgba(0, 0, 0, 0.5);
  opacity: 0.5;
  font-size: 1.125rem;
  position: absolute;
  top: 17px;
  left: 0;
  margin: 0;
  display: none;
}

/* line 123, ../sass/style.scss */
.form-group a {
  display: inline-block;
  margin-top: 15px;
}

/* line 127, ../sass/style.scss */
.form-group select.form-control {
  font-size: 1.125rem;
}

.confirmation-appointment-detail .form-group .btn {
  border-radius: 6px;
  letter-spacing: 1.1px;
  font-size: 22px;
  font-weight: 600;
  color: #fff;
  margin-top: -10px;
  margin-right: 5px;
  min-width: 182px;
}

/* line 131, ../sass/style.scss */
.btn {
  border-radius: 6px;
  letter-spacing: 1.1px;
  font-size: 22px;
  font-weight: 600;
  color: #fff;
  margin-top: 30px;
  margin-right: 5px;
  min-width: 182px;
}

/* line 140, ../sass/style.scss */
.btn.btn-submit {
  background-color: #005eaf;
}

/* line 143, ../sass/style.scss */
.btn.btn-disable {
  background: #797979;
}

/* line 146, ../sass/style.scss */
.btn.btn-delivery {
  background: transparent !important;
  color: #f37800 !important;
  border: 2px solid #f37800;
}

/* line 151, ../sass/style.scss */
.btn:focus,
.btn:hover {
  box-shadow: none;
  outline: none;
  color: #fff;
}

/* line 160, ../sass/style.scss */
.login-page .btn-submit {
  text-align: center;
  width: 100%;
}

/* line 166, ../sass/style.scss */
.btn-wrapper {
  width: 100%;
}

/* line 168, ../sass/style.scss */
.btn-wrapper .btn {
  margin-left: 10px;
  margin-right: 10px;
}

/* Header */
/* line 174, ../sass/style.scss */
header {
  background-color: #005eaf;
  position: absolute;
  padding: 8px 0;
  z-index: 99;
  width: 100%;
}

/* line 181, ../sass/style.scss */
header .logo img {
  width: 106px;
  height: 44px;
}

header .d-flex .home-icon {
  margin-right: 60px;
}

header .d-flex .facilityandlane-label {
  margin-right: 5px;
  display: inline-block;
  color: #f37800;
  font-size: 24px;
  letter-spacing: 1.1px;
  font-weight: 600;
}

header .d-flex .input-dots {
  margin: 0 0 0 10px;
  display: inline-block;
  padding: 5px 15px;
  background: transparent;
  border: 1px solid #fff;
  border-radius: 8px;
  color: #fff;
  line-height: 20px;
  vertical-align: middle;
  font-weight: 600;
  letter-spacing: 1.1px;
}
header .d-flex span input:hover {
  background-color: #fff;
  color: #005eaf;
}

header .d-flex span input:focus,
header .d-flex span input:active {
  outline: none;
  box-shadow: none;
}


.language-btn.btn,
.help-btn.btn {
  margin: 0 0 0 15px;
  background: transparent;
  border: 1px solid #fff;
  line-height: 20px;
  padding: 5px 12px;
  min-width: 0;
  font-size: 16px;
  background-color: transparent;
}

.modal .btn.help-close-btn {
  margin-top: 3rem;
}

/* line 186, ../sass/style.scss */
.body-container {
  min-height: 100vh;
  background: rgba(53, 157, 229, 0.12) url(/static/media/banner-bg.e931ec40.png) no-repeat left bottom/contain;
  padding-top: 90px;
  padding-bottom: 2rem;
}

/* line 191, ../sass/style.scss */
.body-container section {
  width: 100%;
}

/* line 193, ../sass/style.scss */
.body-container section:after,
.body-container section:before {
  content: "";
  display: table;
  clear: both;
}

/* line 202, ../sass/style.scss */
.login-page .body-container {
  padding-top: 0;
  padding-bottom: 0;
}

/* line 206, ../sass/style.scss */
.login-form-section {
  position: relative;
  background: url(/static/media/index-banner.40e3b2db.png) no-repeat left bottom/cover;
}

/* line 209, ../sass/style.scss */
.login-form-section .form-wrapper {
  display: flex;
  align-items: center;
  padding-right: 9.5%;
  justify-content: flex-end;
  min-height: 100vh;
}

/* line 215, ../sass/style.scss */
.login-form-section .form-wrapper .login-form {
  width: 380px;
}

/* line 217, ../sass/style.scss */
.login-form-section .form-wrapper .login-form img {
  margin-bottom: 1.75rem;
}

/* line 220, ../sass/style.scss */
.login-form-section .form-wrapper .login-form h3.form-title {
  position: relative;
}

/* line 222, ../sass/style.scss */
.login-form-section .form-wrapper .login-form h3.form-title:after {
  background: red;
  content: "";
  position: absolute;
  width: 42px;
  height: 2px;
  background-color: #f27a26;
  left: 0;
  top: calc(100% + 9px);
}

/* line 237, ../sass/style.scss */
.tabber-list ul {
  margin: 0;
  padding: 0;
  display: flex;
}

/* line 241, ../sass/style.scss */
.tabber-list ul>li {
  display: block;
}

/* line 243, ../sass/style.scss */
.tabber-list ul>li a {
  font-weight: 600;
  padding: 1.25rem 3rem;
  font-size: 1.5rem;
  letter-spacing: 1px;
  display: block;
  color: rgba(0, 0, 0, 0.45);
  background-color: rgba(0, 0, 0, 0.05);
  letter-spacing: 1.2px;
}

/* line 254, ../sass/style.scss */
.tabber-list ul>li.active a {
  color: #005eaf;
  background-color: #fff;
}

/* line 262, ../sass/style.scss */
.tabber-container {
  display: none;
}

/* line 266, ../sass/style.scss */
.checkout-process-tabber .checkout-process-container {
  display: none;
}

/* line 268, ../sass/style.scss */
.checkout-process-tabber .checkout-process-container:first-child {
  display: block;
}

/* line 271, ../sass/style.scss */
.checkout-process-tabber .checkout-process-container .confirmation-detail {
  padding: 1.5rem 1.75rem;
}

/* line 273, ../sass/style.scss */
.checkout-process-tabber .checkout-process-container .confirmation-detail+.confirmation-detail {
  margin-top: 2rem;
}

/* line 276, ../sass/style.scss */
.checkout-process-tabber .checkout-process-container .confirmation-detail>.confirmation-trailer-detail {
  width: calc(44.365% - 50px);
}

/* line 279, ../sass/style.scss */
.checkout-process-tabber .checkout-process-container .confirmation-detail>.confirmation-appointment-detail {
  width: 55.635%;
  margin-left: 50px;
  padding-left: 50px;
  position: relative;
}

/* line 284, ../sass/style.scss */
.checkout-process-tabber .checkout-process-container .confirmation-detail>.confirmation-appointment-detail:after {
  content: "";
  position: absolute;
  border-left: 1px solid #bbbbbb;
  top: 4rem;
  right: 100%;
  bottom: 20px;
}

/* line 293, ../sass/style.scss */
.checkout-process-tabber .checkout-process-container .confirmation-detail h4 {
  margin-bottom: 2rem;
  width: 100%;
}

/* line 297, ../sass/style.scss */
.checkout-process-tabber .checkout-process-container .confirmation-detail ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

/* line 301, ../sass/style.scss */
.checkout-process-tabber .checkout-process-container .confirmation-detail ul li {
  display: flex;
  margin: 20px 0;
  font-size: 20px;
}

/* line 305, ../sass/style.scss */
.checkout-process-tabber .checkout-process-container .confirmation-detail ul li strong {
  display: inline-block;
  width: 220px;
  padding-right: 15px;
}

/* line 314, ../sass/style.scss */
.checkout-process-tabber .checkout-process-container.checkin-appointment-container .confirmation-detail {
  padding: 1.5rem 1.75rem;
}

/* line 316, ../sass/style.scss */
.checkout-process-tabber .checkout-process-container.checkin-appointment-container .confirmation-detail h4 {
  margin-bottom: 1.5rem;
}

/* line 320, ../sass/style.scss */
.checkout-process-tabber .checkout-process-container.checkin-appointment-container .confirmation-detail ul li {
  padding-right: 25px;
}

/* line 322, ../sass/style.scss */
.checkout-process-tabber .checkout-process-container.checkin-appointment-container .confirmation-detail ul li strong {
  width: 175px;
}

.checkout-process-tabber .checkout-process-container.checkin-appointment-container .confirmation-detail ul li strong+span {
  width: calc(100% - 175px);
}

/* line 327, ../sass/style.scss */
.checkout-process-tabber .checkout-process-container.checkin-appointment-container .confirmation-detail .btn {
  min-width: 120px;
}

.checkout-process-tabber .checkout-process-container .d-flex .form-group {
  min-width: 350px;
}

/* line 333, ../sass/style.scss */
.checkout-process-tabber>ul {
  margin: 0 auto;
  padding: 0;
  justify-content: center;
}

/* line 337, ../sass/style.scss */
.checkout-process-tabber>ul>li {
  display: block;
  width: 200px;
  text-align: center;
  padding: 0 20px 4rem;
  position: relative;
}

/* line 343, ../sass/style.scss */
.checkout-process-tabber>ul>li:first-child {
  width: auto;
}

/* line 346, ../sass/style.scss */
.checkout-process-tabber>ul>li:nth-child(n + 2):not(:last-child):before {
  content: "";
  border-top: 1px dashed #b7b7b7;
  top: 27px;
  left: 50%;
  right: 50%;
  position: absolute;
  width: 100%;
}

/* line 354, ../sass/style.scss */
.checkout-process-tabber>ul>li h4 {
  color: rgba(0, 0, 0, 0.9);
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 1.2px;
  margin: 15px 0;
  white-space: nowrap;
}

/* line 363, ../sass/style.scss */
.checkout-process-tabber>ul>li>span.count {
  display: block;
  height: 54px;
  width: 54px;
  margin: 0 auto 15px;
  border-radius: 50%;
  font-size: 1.5rem;
  padding: 8px;
  text-align: center;
  background: #deedf7;
  position: relative;
}

/* line 377, ../sass/style.scss */
.checkout-process-tabber>ul>li small {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.8px;
  color: rgba(121, 121, 121, 0.5);
  display: block;
  /* white-space: nowrap; */
}

/* line 388, ../sass/style.scss */
.checkout-process-tabber>ul>li.active>span.count {
  color: #fff;
  background: #0088c8;
}

/* line 393, ../sass/style.scss */
.checkout-process-tabber>ul>li.active small {
  color: #797979;
}

label.dv-star-rating-star.dv-star-rating-empty-star {
  color: transparent !important;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB0AAAAbCAYAAACAyoQSAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAchJREFUeNq8VmtPwkAQbAX6EBSsYBUJxug3o+j//x8mfNDExPgKAtakKBRwN5maprSlt1gvmaQ9bndujtvZappsmIRrQkUSvCUkbRNKhKP/ImWVDqFPaEnUlgSkXcInYYAN1AgfRSoNVb7g/QlqjSKVnkDlCO9zEO6oqFVRahEahOfYPL83VdSWFP9LjzCOzSurXUdaJthQ6BLuCIuEdRPCKWFG0LFmmZZUR2ILl8SKwMTv34QvwhBIG3zEe5E8C8SF8b/gpDdI7iUsmmnyYcSE8HM9zFkFcVMrdriwTjucsDHhFkTIdtmD4pWS6En9NGN0CFdZJWVgQeePCLnMLvP4cwULuxuQ6SihC1TH2jpdoDTauGRjAekZbmsf5pHLBgMYwb5QpYP4uar38k59AekSDmVKDH8bwZIxidajCqm9AamPTYuU+hmNoJnRMDKVljMuQ5JSJjmEc01QVvwV8Rq7NL6E1IIxBxGyA7gV98xbNAROfAxD4Wb+hpKbYuMGnnP1010EjKDqHLfyHomDSGkN0aFacLIllNYjXWvlGNP8soGT4ASPOcunitiwnw4SPm9SlTrY0AOC8vZVXveODdZwEl580Y8AAwCYy2frCTA9CgAAAABJRU5ErkJggg==) no-repeat center center / contain;
}

label.dv-star-rating-star.dv-star-rating-empty-star {
  color: transparent !important;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB0AAAAbCAYAAACAyoQSAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAchJREFUeNq8VmtPwkAQbAX6EBSsYBUJxug3o+j//x8mfNDExPgKAtakKBRwN5maprSlt1gvmaQ9bndujtvZappsmIRrQkUSvCUkbRNKhKP/ImWVDqFPaEnUlgSkXcInYYAN1AgfRSoNVb7g/QlqjSKVnkDlCO9zEO6oqFVRahEahOfYPL83VdSWFP9LjzCOzSurXUdaJthQ6BLuCIuEdRPCKWFG0LFmmZZUR2ILl8SKwMTv34QvwhBIG3zEe5E8C8SF8b/gpDdI7iUsmmnyYcSE8HM9zFkFcVMrdriwTjucsDHhFkTIdtmD4pWS6En9NGN0CFdZJWVgQeePCLnMLvP4cwULuxuQ6SihC1TH2jpdoDTauGRjAekZbmsf5pHLBgMYwb5QpYP4uar38k59AekSDmVKDH8bwZIxidajCqm9AamPTYuU+hmNoJnRMDKVljMuQ5JSJjmEc01QVvwV8Rq7NL6E1IIxBxGyA7gV98xbNAROfAxD4Wb+hpKbYuMGnnP1010EjKDqHLfyHomDSGkN0aFacLIllNYjXWvlGNP8soGT4ASPOcunitiwnw4SPm9SlTrY0AOC8vZVXveODdZwEl580Y8AAwCYy2frCTA9CgAAAABJRU5ErkJggg==) no-repeat center center / contain;
}

label.dv-star-rating-star.dv-star-rating-full-star {
  color: transparent !important;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAdCAYAAAC9pNwMAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQ1IDc5LjE2MzQ5OSwgMjAxOC8wOC8xMy0xNjo0MDoyMiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTkgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjBBMzRENDRDNzJGRTExRTk4Nzk5QUJENzQ0QzFBMkEyIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjBBMzRENDRENzJGRTExRTk4Nzk5QUJENzQ0QzFBMkEyIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MEEzNEQ0NEE3MkZFMTFFOTg3OTlBQkQ3NDRDMUEyQTIiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6MEEzNEQ0NEI3MkZFMTFFOTg3OTlBQkQ3NDRDMUEyQTIiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz5D3tmtAAABpUlEQVR42mL8s0WUgUwQAKU3kKOZhYF8MB+IGYFYgBzNTGRaqg+1kB/KppvF3UjsXnpZrA7Erkh8ZyDWoIfFXVjEemhtsQIQ+2ER9wZiZVpa3IFHrpMUgxhJyMeCQPyOgBoxIH5NjXwsBMTyQKwIxDlEmLcGiKcA8X0gfojPESAfq0MdIA+NJxhWgsYpF5lZ7gcQPwLie1B8F4pBYr9BFoYBcRMD9QEHEKtBMTpoBCWuZiCew0A/MBOIG2CpOpXcEohEALIjAz07laAVhdQGPVA7sObjMlLzIwlleymhAqQCiNupaGkn1ENElVxVQNxKBUs7oB4hqcisAeKFFFi6BIgryS2rP1Bg8VdKKglbCiy2I9diDmilTy5QgTaNSLYYVNRxU2AxK76WCROBJg6lgCyLNfHIXQJidyB2AeKzeNRpUcviR9CyFtSk3QXEe4HYBIiTgPgOKY5nIjKYPkDzpCq0dsHWuAeliSIgfkVJUINaHgZI5awytBT6hceh/4G4H6oWVNX+hjpUlhSLtYF4JTSBlRHR1kIGX4C4DpqdFuMKboAAAwCYv0VeGAvuVQAAAABJRU5ErkJggg==) no-repeat center center / contain;
}

/* line 400, ../sass/style.scss */
.modal-dialog {
  transform: translate(0, 0);
  top: 0;
  transition: 0.3s all;
  margin: auto;
}

/* line 406, ../sass/style.scss */
.modal.show .modal-dialog {
  transform: translate(0, -50%);
  top: 50%;
}

/* line 410, ../sass/style.scss */
a[data-toggle="modal"] {
  vertical-align: bottom;
}

/* line 413, ../sass/style.scss */
.modal-body {
  padding: 2rem;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.9px;
  color: #000000;
}

/* line 419, ../sass/style.scss */
.modal-body.confirmation-modal-body {
  padding: 0;
}

/* line 421, ../sass/style.scss */
.modal-body.confirmation-modal-body h5 {
  margin: 0;
  padding: 3rem 4rem;
  text-align: center;
  color: #525252;
}

/* line 427, ../sass/style.scss */
.modal-body.confirmation-modal-body .btn {
  border-radius: 0;
  width: 50%;
  margin: 0;
  font-size: 1.375rem;
  font-weight: 600;
  letter-spacing: 1.1px;
}

/* line 436, ../sass/style.scss */
.modal-body h4.modal-title {
  margin-bottom: 1em;
}

/* line 440, ../sass/style.scss */
.modal-body ol li,
.modal-body ul li {
  margin: 0 0 1em;
}

/* line 444, ../sass/style.scss */
.modal-body button {
  font-size: 1rem;
  margin: 1rem 0 0;
  width: 110px;
}

/* line 451, ../sass/style.scss */
.modal-dialog.confirmation-modal-dialog,
.ReactModal__Content.ReactModal__Content--after-open .modal.confirmation-modal-dialog {
  max-width: 380px;
}

/* line 453, ../sass/style.scss */
.modal-dialog.confirmation-modal-dialog .modal-content {
  overflow: hidden;
}

/* line 458, ../sass/style.scss */
.signature-boundary {
  min-height: 195px;
  border: 1px solid #797979;
  position: relative;
  margin-bottom: 0.25rem;
}

.signature-boundary canvas {
  width: 100%;
  height: auto;
  vertical-align: bottom;
}

/* line 463, ../sass/style.scss */
.signature-info h5 {
  display: block;
  text-align: right;
  padding: 20px;
  margin: 0;
}

/* line 467, ../sass/style.scss */
.signature-info {
  position: relative;
}

.signature-info h5 a {
  /* text-decoration: underline; */
  margin: 0;
}

/* line 471, ../sass/style.scss */
.signature-info .alert-msg {
  display: block;
  text-align: right;
  bottom: 18px;
  padding: 0 15px 20px;
  color: #d32e2e;
  letter-spacing: 0.8px;
}

/* line 479, ../sass/style.scss */
span.seal-number {
  background-color: rgba(0, 136, 200, 0.12);
  padding: 0 8px;
  font-size: 1.25rem;
  color: rgba(0, 94, 175, 0.8);
  font-weight: 600;
  letter-spacing: 1px;
}

/* line 488, ../sass/style.scss */
.delivery-access-body h5 {
  color: #575757 !important;
}

/* line 491, ../sass/style.scss */
.delivery-access-body .d-flex {
  margin: 2rem -2rem -2rem;
}

/* line 493, ../sass/style.scss */
.delivery-access-body .d-flex .btn {
  width: 50%;
  border-radius: 0;
  font-size: 1.375rem;
}

.delivery-access-body .d-flex.justify-content-center .btn {
  margin: 1rem 0;
  border-radius: 8px;
}

/* line 500, ../sass/style.scss */
.delivery-detail {
  font-size: 1.125rem;
}

/* line 502, ../sass/style.scss */
.delivery-detail h4 {
  margin-bottom: 1.5rem;
}

/* line 506, ../sass/style.scss */
.delivery-detail p strong {
  margin-right: 15px;
}

/* line 511, ../sass/style.scss */
.delivery-detail .d-flex .form-group {
  width: calc(50% - 20px);
}

/* line 513, ../sass/style.scss */
.delivery-detail .d-flex .form-group:nth-child(2n) {
  margin-left: 40px;
}

/* line 517, ../sass/style.scss */
.delivery-detail .d-flex .form-group .form-control+.form-control {
  margin-left: 2rem;
}

/* line 521, ../sass/style.scss */
.delivery-detail .d-flex .form-group strong {
  margin-right: 0.75rem;
  margin-left: 0.75rem;
  margin-top: 10px;
  display: inline-block;
}

/* line 526, ../sass/style.scss */
.delivery-detail .d-flex .form-group .radio-group {
  margin-right: 1.5rem;
  margin-top: 10px;
}

/* line 533, ../sass/style.scss */
.tick {
  margin: 1.375rem 0 3.25rem;
}

/* line 537, ../sass/style.scss */
.gate-allotted h4 {
  color: #00af3a;
  margin: 0 0 2rem;
  font-weight: bold;
}

/* line 541, ../sass/style.scss */
.gate-allotted h6 {
  color: #000;
  margin-bottom: 1.5rem;
  font-weight: bold;
}

/* line 545, ../sass/style.scss */
.gate-allotted p {
  color: #797979;
  letter-spacing: 0.8px;
}

/* line 550, ../sass/style.scss */
.language-section h4 {
  color: #4d4d4d;
}

/* line 554, ../sass/style.scss */
.terms-listing .row {
  color: #000;
  font-size: 0.75rem;
  margin-bottom: 2rem;
  font-weight: 600;
  letter-spacing: 0.9px;
}

/* line 560, ../sass/style.scss */
.terms-listing .row>div {
  margin: 5px 0;
}

/* line 565, ../sass/style.scss */
.terms-listing form label {
  position: relative;
  color: #797979;
  letter-spacing: 0.8px;
}

/* line 569, ../sass/style.scss */
.terms-listing form label input[type="checkbox"] {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 9;
  opacity: 0;
}

/* line 575, ../sass/style.scss */
.terms-listing form label input[type="checkbox"]+span {
  position: relative;
  padding-left: 40px;
}

/* line 578, ../sass/style.scss */
.terms-listing form label input[type="checkbox"]+span:before {
  content: "";
  width: 24px;
  height: 24px;
  border-radius: 5px;
  border: solid 2px #acacac;
  position: absolute;
  left: 0;
  top: 0;
}

/* line 590, ../sass/style.scss */
.terms-listing form label input[type="checkbox"]:checked+span {
  color: #005eaf;
}

/* line 592, ../sass/style.scss */
.terms-listing form label input[type="checkbox"]:checked+span:before {
  border-color: #005eaf;
}

/* line 595, ../sass/style.scss */
.terms-listing form label input[type="checkbox"]:checked+span:after {
  content: "";
  height: 13px;
  width: 7px;
  border-right: 2px solid #005eaf;
  border-bottom: 2px solid #005eaf;
  position: absolute;
  left: 9px;
  top: 4px;
  transform: rotate(45deg);
}

textarea {
  resize: none;
}

.ReactModal__Content.ReactModal__Content--after-open {
  border: none !important;
  border-radius: 0 !important;
  padding: 0 !important;
  background: transparent !important;
  width: 100% !important;
  transform: none !important;
  left: 0 !important;
  top: 0 !important;
  height: 100vh;
}

.ReactModal__Content.ReactModal__Content--after-open .modal {
  width: 100%;
  max-width: 550px;
  margin: 0 auto;
  border-radius: 8px;
  border: none;
  padding: 2rem;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.9px;
  color: #000000;
  display: block;
  background: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  bottom: auto;
  right: auto;
}

.ReactModal__Content.ReactModal__Content--after-open .modal ol {
  max-height: 250px;
  overflow: auto;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
  background: rgba(0, 0, 0, 0.5) !important;
  z-index: 99;
}

.ReactModal__Content.ReactModal__Content--after-open h2 {
  font-size: 24px;
  font-weight: 600 !important;
  letter-spacing: 1.2px;
  color: #005eaf !important;
  margin-bottom: 1em;
  line-height: 1.5;
}

.ReactModal__Content.ReactModal__Content--after-open .feedback-modal h2 {
  font-size: 22px;
}

.ReactModal__Content.ReactModal__Content--after-open ol li,
.ReactModal__Content.ReactModal__Content--after-open ul li {
  margin: 0 0 1em;
}

.ReactModal__Content.ReactModal__Content--after-open button {
  font-size: 1rem;
  margin: 1rem 0 0 auto;
  width: 110px;
  float: right;
  border-radius: 6px;
  letter-spacing: 1.1px;
  font-weight: 600;
  color: #fff;
  min-width: 182px;
  -webkit-appearance: button;
  background-color: #005eaf;
  box-shadow: none;
  border: none;
  padding: 0.375rem 0.75rem;
}

.ReactModal__Content.ReactModal__Content--after-open center button {
  float: none;
}

.ReactModal__Content.ReactModal__Content--after-open button.btn-disable {
  background: #797979;
}

.ReactModal__Content.ReactModal__Content--after-open .btn-alignment {
  text-align: right;
}

.ReactModal__Content.ReactModal__Content--after-open .btn-alignment button {
  width: auto;
  float: none;
  min-width: 0;
  margin-left: 15px;
}

.rating-stars {
  font-size: 40px;
}

.dv-star-rating label {
  margin: 0 10px;
}

.loaderContainer {
  text-align: center;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  /* pointer-events: none; */
  height: 100%;
  background: #00000045;
  z-index: 999;
}

.preloader-wrapper {
  position: absolute;
  width: 50px;
  height: 50px;
  top: calc(50% - 25px);
  left: calc(50% - 25px);
  z-index: 999;
}

.preloader-wrapper.active {
  animation: container-rotate 1568ms linear infinite;
}

@keyframes container-rotate {
  to {
    transform: rotate(360deg);
  }
}

.spinner-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  border-color: #26a69a;
}

.spinner-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  border-color: #26a69a;
}

.spinner-blue,
.spinner-blue-only {
  border-color: #4285f4;
}

.active .spinner-layer,
.active .spinner-layer.spinner-blue-only,
.active .spinner-layer.spinner-red-only,
.active .spinner-layer.spinner-yellow-only,
.active .spinner-layer.spinner-green-only {
  opacity: 1;
  animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

@keyframes fill-unfill-rotate {
  12.5% {
    transform: rotate(135deg);
  }

  25% {
    transform: rotate(270deg);
  }

  37.5% {
    transform: rotate(405deg);
  }

  50% {
    transform: rotate(540deg);
  }

  62.5% {
    transform: rotate(675deg);
  }

  75% {
    transform: rotate(810deg);
  }

  87.5% {
    transform: rotate(945deg);
  }

  to {
    transform: rotate(1080deg);
  }
}

.circle-clipper {
  display: inline-block;
  position: relative;
  width: 50%;
  height: 100%;
  overflow: hidden;
  border-color: inherit;
}

.circle {
  border-radius: 50%;
}

.circle-clipper .circle {
  width: 200%;
  height: 100%;
  border-width: 3px;
  border-style: solid;
  border-color: inherit;
  border-bottom-color: transparent !important;
  border-radius: 50%;
  animation: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
}

.circle-clipper.left .circle {
  left: 0;
  border-right-color: transparent !important;
  transform: rotate(129deg);
}

.active .circle-clipper.left .circle {
  animation: left-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

.active .circle-clipper.right .circle {
  animation: right-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

.circle-clipper.right .circle {
  left: -100%;
  border-left-color: transparent !important;
  transform: rotate(-129deg);
}

.gap-patch {
  position: absolute;
  top: 0;
  left: 45%;
  width: 10%;
  height: 100%;
  overflow: hidden;
  border-color: inherit;
}

.gap-patch .circle {
  width: 1000%;
  left: -450%;
}

.circle-clipper {
  display: inline-block;
  position: relative;
  width: 50%;
  height: 100%;
  overflow: hidden;
  border-color: inherit;
}

.right {
  float: right !important;
}

@keyframes left-spin {
  from {
    transform: rotate(130deg);
  }

  50% {
    transform: rotate(-5deg);
  }

  to {
    transform: rotate(130deg);
  }
}

@keyframes right-spin {
  from {
    transform: rotate(-130deg);
  }

  50% {
    transform: rotate(5deg);
  }

  to {
    transform: rotate(-130deg);
  }
}

/* react-select option css change */
.form-group .form-control .custom-select__control {
  border: none;
  min-height: 23px;
  max-height: 23px;
  box-shadow: none;
}

.form-group .form-control .custom-select__value-container {
  padding: 0;
}

.form-group .form-control .custom-select__placeholder {
  color: rgba(0, 0, 0, 0.25);
}

.form-group .form-control .custom-select__single-value {
  color: rgba(0, 0, 0, 0.5);
}

.form-group .form-control .custom-select__menu {
  color: #495057;
}

/* .form-group .form-control .custom-select__indicators {
  display: none;
} */

.form-group .form-control .custom-select__option--is-focused {
  background: #007bff;
  color: white;
}

.form-group .form-control .custom-select__option--is-focused:hover {
  background: #007bff;
  color: white;
}

.qr-reader-container .qr-reader {
  width: 230px;
}

.qr-reader>section>div {
  border: none !important;
  box-shadow: none !important;
  /* box-shadow: #000000 0px 0px 0px 1px inset !important; */
}

.gate-pass {
  display: flex;
}

.gate-form {
  margin-left: 7%;
}

.vertical-line {
  border-left: 1px solid;
  border-left-color: #797979;
  height: 90px;
  top: 110px;
  margin-left: 16px;
}

.or-text {
  font-size: 18px;
  color: #797979;
  top: 110px;
  margin-left: 35%;
}

.vertical-line-two {
  border-left: 1px solid;
  border-left-color: #797979;
  top: 110px;
  height: 90px;
  margin-left: 16px;
}

.gate-pass-partition {
  margin-top: 8%;
}

.progress-bar-bottom {
  margin-top: 30px;
}

/* ---------------------keyboard css ------------------------ */
.row {
  flex-direction: row;
}

.keyboard {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9;
  background: transparent;
}

.keyboard-container {
  padding-bottom: 140px !important;
}

.keyboard2-container {
  width: 400px;
}

.lane-btn.btn {
  margin: 0 0 0 15px;
  background: transparent;
  border: 1px solid #fff;
  line-height: 20px;
  padding: 10px 48px;
  min-width: 0;
  font-size: 22px;
  background-color: #005eaf;
}

.selectLane {
  background-color: #548dd8 !important;
}

.feedback-msg {
  font-size: 14px;
  color: #005eaf !important;
}

label.wrap {
  display: block;
}

label.wrap:after {
  content: "\2304";
  position: absolute;
  right: 0;
  top: 18px;
  pointer-events: none;
}

.gate-label {
  display: flex;
  margin: 0 auto;
  width: 586px;
  color: #f37800;
  font-size: 22px;
  letter-spacing: 1.1px;
  font-weight: 600;
}

@keyframes invalidEntryPulse {
  0% {background-color: rgb(255, 255, 255);}
  50% {background-color: rgb(255, 224, 224);}
  100% {background-color: rgb(255, 255, 255);}
}

.form-group .invalidEntryStyle {
  border: solid red 2px;
  border-radius: 5px;
  padding: 17px 5px;
  animation: invalidEntryPulse 5s infinite ease-in-out;
}

.form-group .invalidEntryStyle::placeholder {
  color: red;
}


/* @media (max-width: 768px) {
  .gate-label {
    margin-left: 100px;
  }
} */


